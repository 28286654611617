import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import TopLeftImage from "../../assets/client/services/top-left.svg";
import BottomRightImage from "../../assets/client/services/bottom-right.svg";
import Placement from "../../assets/client/services/placement.svg";
import Advertisements from "../../assets/client/services/advertisement.svg";
import ReachOut from "../../assets/client/services/reach-out.svg";
import ProfileShare from "../../assets/client/services/profile-share.svg";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);
  return (
    <section className="my-8 sm:my-16 block" ref={ref}>
      <div
        className={`sm:h-full bg-no-repeat sm:bg-left-top`}
        style={{ backgroundImage: `url(${TopLeftImage})` }}
      >
        <div
          className={` sm:h-full bg-no-repeat bg-[right_bottom_-3rem]`}
          style={{ backgroundImage: `url(${BottomRightImage})` }}
        >
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -20 },
            }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            className="text-5xl font-semibold mb-8 text-center"
          >
            <span className="text-orange">{t("client_services")}</span>
          </motion.h2>
          <div className="grid grid-col lg:grid-cols-2 gap-4 xl:gap-10 m-8 sm:m-16 pb-20">
            <div className="p-8 border-[#FEBE10] border-4 rounded-[30px] flex flex-col sm:flex-row items-center gap-8 bg-white shadow-xl group hover:bg-green/[0.5]">
              <img
                src={Placement}
                loading='lazy'
                className="group-hover:rotate-45 transition-all duration-400 ease-in-out"
                alt=""
              />
              <div className="flex flex-col text-center sm:text-left">
                <span className="text-3xl font-medium group-hover:text-blue">
                  {t("client_placement")}
                </span>
                <p className="text-slate-600 group-hover:text-white">
                  {t("client_we_will_find")}
                </p>
              </div>
            </div>
            <div className="p-8 border-[#FEBE10] border-4 rounded-[30px] flex flex-col sm:flex-row items-center gap-8 bg-white shadow-xl group hover:bg-green/[0.5]">
              <img
                src={Advertisements}
                loading='lazy'
                className="group-hover:rotate-45 transition-all duration-400 ease-in-out"
                alt=""
              />
              <div className="flex flex-col text-center sm:text-left">
                <span className="text-3xl font-medium group-hover:text-blue">
                  {t("client_advertisements")}
                </span>
                <p className="text-slate-600 group-hover:text-white">
                  {t("client_we_will_provide")}
                </p>
              </div>
            </div>
            <div className="p-8 border-[#FEBE10] border-4 rounded-[30px] flex flex-col sm:flex-row items-center gap-8 bg-white shadow-xl group hover:bg-green/[0.5]">
              <img
                src={ReachOut}
                loading='lazy'
                className="group-hover:rotate-45 transition-all duration-400 ease-in-out"
                alt=""
              />
              <div className="flex flex-col text-center sm:text-left">
                <span className="text-3xl font-medium group-hover:text-blue">
                  {t("client_reach_out")}
                </span>
                <p className="text-slate-600 group-hover:text-white">
                  {t("client_our_engine")}
                </p>
              </div>
            </div>
            <div className="p-8 border-[#FEBE10] border-4 rounded-[30px] flex flex-col sm:flex-row items-center gap-8 bg-white shadow-xl group hover:bg-green/[0.5]">
              <img
                src={ProfileShare}
                loading='lazy'
                className="group-hover:rotate-45 transition-all duration-400 ease-in-out"
                alt=""
              />
              <div className="flex flex-col text-center sm:text-left">
                <span className="text-3xl font-medium group-hover:text-blue">
                  {t("client_profile_share")}
                </span>
                <p className="text-slate-600 group-hover:text-white">
                  {t("client_find_top")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
