import Line from "../../assets/partners/line.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function Partners() {
  const { t } = useTranslation();

  return (
    <section className="m-8 sm:m-16">
      <span className="text-5xl text-orange font-semibold block">
        {t("home_partners")}
      </span>
      <span className="block p-1 text-lg font-semibold sm:me-10 text-[#5C5B5B]/70">
        {t("home_partners_do_good_partnership")}
        <br />

        {t("home_partners_partner_info")}
      </span>
      <div className="flex sm:flex-col gap-10 justify-around sm:justify-center items-center mt-10 sm:mt-20">
        <div className="hidden sm:block sm:flex flex-col sm:flex-row justify-around sm:justify-center sm:items-center max-w-screen-2xl">
          <span className="text-3xl lg:text-6xl font-semibold rounded-full bg-green py-4 px-6 align-middle lg:py-8 lg:px-12 text-white aspect-square">
            1
          </span>
          <img
            src={Line}
            loading='lazy'
            alt="dotted line"
            className="relative place-self-center hidden sm:block sm:w-1/5 lg:w-1/4 xl:w-full"
          />
          <span className="text-3xl lg:text-6xl font-semibold rounded-full bg-green py-4 px-6 align-middle lg:py-8 lg:px-12 text-white aspect-square">
            2
          </span>
          <img
            src={Line}
            loading='lazy'
            alt="dotted line"
            className="relative place-self-center hidden sm:block sm:w-1/5 lg:w-1/4 xl:w-full"
          />
          <span className="text-3xl lg:text-6xl font-semibold rounded-full bg-green py-4 px-6 align-middle lg:py-8 lg:px-12 text-white aspect-square">
            3
          </span>
        </div>
        <div className="flex flex-col sm:flex-row justify-between 2xl:justify-evenly items-center sm:items-start gap-4 sm:gap-0 xl:gap-4 max-w-screen-2xl">
          <div className="flex sm:w-4/12 xl:w-1/4 sm:flex-col justify-center items-start gap-4 z-10">
            <div className="">
              <span className="flex justify-center items-center text-3xl inline-block w-[80px] align-bottom font-semibold rounded-full bg-green py-4 px-8 text-white aspect-square sm:hidden">
                <p>1</p>
              </span>
            </div>
            <div className="text-start sm:text-center">
              <span className="text-2xl font-medium text-pretty">
                {t("home_partners_download_partner_app")}
              </span>
              <p>{t("home_partners_setup_partner_app")}</p>
            </div>
          </div>
          <div className="flex sm:w-4/12 xl:w-1/4 sm:flex-col justify-center items-start gap-4 z-10">
            <div className="">
              <span className="flex justify-center items-center text-3xl inline-block w-[80px] align-bottom font-semibold rounded-full bg-green py-4 px-8 text-white aspect-square sm:hidden">
                <p>2</p>
              </span>
            </div>
            <div className="text-start sm:text-center">
              <span className="text-2xl font-medium text-pretty">
                {t("home_partners_refer_candidates_clients")}
              </span>
              <p>{t("home_partners_refer_and_unlock")}</p>
            </div>
          </div>
          <div className="flex sm:w-4/12 xl:w-1/4 sm:flex-col justify-center items-start gap-4 z-10">
            <div className="">
              <span className="flex justify-center items-center text-3xl inline-block w-[80px] align-bottom font-semibold rounded-full bg-green py-4 px-8 text-white aspect-square sm:hidden">
                <p>3</p>
              </span>
            </div>
            <div className="text-start sm:text-center">
              <span className="text-2xl font-medium text-pretty">
                {t("home_partners_earn_rewards_refer")}
              </span>
              <p>{t("home_partners_boost_your_earnings")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-8 sm:mt-10 sm:mt-32 sm:mb-12 flex flex-col sm:flex-row gap-4 sm:gap-10 sm:gap-0 justify-center items-center font-semibold">
        <a
          className="bg-orange px-8 py-4 sm:py-6 sm:px-10 text-white text-2xl rounded-[20px] sm:me-16 w-4/5 md:w-auto hover:bg-green"
          href={`https://play.google.com/store/apps/details?id=com.kaigal.partnersapp&hl==${i18n.language}_IN&gl=US"`}
          target="_blank"
          rel="noreferrer"
        >
          {t("home_partners_download")}
        </a>
        <a
          className="bg-orange px-8 py-4 sm:py-6 sm:px-10 text-white text-2xl rounded-[20px] w-full md:w-auto hover:bg-green"
          href="https://partner.kaigal.com/?utm_source=website&utm_medium=visit_the_site&utm_campaign=partner#/signupcheck"
          target="_blank"
          rel="noreferrer"
        >
          {t("home_partners_visit_site")}
        </a>
      </div>
    </section>
  );
}
