import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import en from "./translations/en.json";
import te from "./translations/te.json";
import as from "./translations/as.json";
import bn from "./translations/bn.json";
import gu from "./translations/gu.json";
import kn from "./translations/kn.json";
import ml from "./translations/ml.json";
import mr from "./translations/mr.json";
import or from "./translations/or.json";
import ta from "./translations/ta.json";
import hi from "./translations/hi.json";

const resources = {
    en: { translation: en },
    te: { translation: te },
    as: { translation: as },
    bn: { translation: bn },
    gu: { translation: gu },
    kn: { translation: kn },
    ml: { translation: ml },
    mr: { translation: mr },
    or: { translation: or },
    ta: { translation: ta },
    hi: { translation: hi },
}

// Function to detect browser locales
function getBrowserLocales(options = {}) {
    const defaultOptions = {
        languageCodeOnly: true,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };

    // const browserLocales =
  //   navigator.languages === undefined
    //     ? [navigator.language]
  //     : navigator.languages;
    const browserLocales = navigator.languages || [navigator.language];

    // if (!browserLocales) {
    //   return undefined;
    // }

    // return browserLocales.map((locale) => {
    //   const trimmedLocale = locale.trim();
    //   return opt.languageCodeOnly
    //     ? trimmedLocale.split(/-|_/)[0] // Extract language code only
    //     : trimmedLocale;
    // });
    return browserLocales.map((locale) =>
        opt.languageCodeOnly ? locale.split(/-|_/)[0] : locale
    );
}

// Detect the preferred language from the browser
// const browserLocales = getBrowserLocales({ languageCodeOnly: true });
// const detectedLanguage = browserLocales ? browserLocales[0] : "en"; // Default to English if not detected
// Initialize i18n with detected language


const browserLocales = getBrowserLocales();
const userSelectedLanguage = localStorage.getItem("preferredLanguage");
const detectedLanguage = userSelectedLanguage || browserLocales[0] || "en";


i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        // debug: true,
        // react: {
        //   useSuspense: true, // Enable Suspense for lazy-loaded translations
        // },
        resources,
        lng: detectedLanguage, // Use detected browser language
        fallbackLng: "en", // Fallback to English
        interpolation: {
            escapeValue: false, // React already handles escaping
        },
    });

export default i18n;
