import StepOneImage from "../../assets/home/howItWorks/app-1.webp";
import StepTwoImage from "../../assets/how-it-works/app-ss-2.png";
import StepThreeImage from "../../assets/how-it-works/app-ss-3.png";
import StepFourImage from "../../assets/how-it-works/app-ss-4.png";
import PatternLeft from "../../assets/home/howItWorks/patter-left-top.webp";
import IllusLeft from "../../assets/home/howItWorks/mobile-arrow-top.webp";
import BgVector from "../../assets/how-it-works/bg-vector-wave.svg";
import PatternRight from "../../assets/home/howItWorks/pattern-right-bottom.webp";
import IllusRight from "../../assets/home/howItWorks/mobile-arrow-bottom.webp";
import OneTwoArrow from "../../assets/how-it-works/1-2-arrow.svg";
import TwoThreeArrow from "../../assets/how-it-works/2-3-arrow.svg";
import ThreeFourArrow from "../../assets/how-it-works/3-4-arrow.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function HowItWorks() {
  const { t } = useTranslation();
  return (
    <section className="my-16">
      <h6 className="text-5xl text-orange text-end font-semibold mb-4 me-10">
        {t("home_how_it_works")}
      </h6>
      <span className="text-lg text-end block font-semibold me-10 text-[#5C5B5B]/70">
        {t("home_how_it_works_find_jobs_steps")}
      </span>
      <div
        className={`bg-no-repeat bg-[top_left_-2rem] sm:bg-left-top`}
        style={{ backgroundImage: `url(${PatternLeft})` }}
      >
        <div
          className={`bg-no-repeat bg-[bottom_right_-1rem] sm:bg-right-bottom`}
          style={{ backgroundImage: `url(${PatternRight})` }}
        >
          <div
            className={`bg-no-repeat bg-center bg-contain`}
            style={{ backgroundImage: `url(${BgVector})` }}
          >
            <div
              style={{ "--home--how-it-works-left-sm-bg": `url(${IllusLeft})` }}
              className={`bg bg-no-repeat md:bg-[top_left_6rem] lg:bg-[image:var(--home--how-it-works-left-sm-bg)]`}
            >
              <div
                style={{
                  "--home--how-it-works-right-sm-bg": `url(${IllusRight})`,
                }}
                className={`bg bg-no-repeat md:bg-[bottom_right_6rem] flex flex-col gap-10 m-8 lg:bg-[image:var(--home--how-it-works-right-sm-bg)]`}
              >
                <div className="flex items-center justify-evenly">
                  <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                        {t("home_how_it_works_step_1")}
                      </span>
                      <span className="text-3xl font-semibold">
                        {t("home_how_it_works_register")}
                      </span>
                    </div>
                    <span className="block text-2xl text-justify">
                      <span className="font-bold">
                        {t("home_how_it_works_sign_up_options_one")}
                      </span>{" "}
                      {t("home_how_it_works_sign_up_options_two")}
                    </span>
                  </div>
                  <div className="drop-shadow-2xl hidden lg:block">
                    <img
                      src={StepOneImage}
                      loading='lazy'
                      alt=""
                      srcSet=""
                      className="lg:scale-110"
                    />
                  </div>
                </div>
                <div className="relative flex items-center justify-evenly">
                  <div className="drop-shadow-2xl hidden lg:block">
                    <img
                      src={StepTwoImage}
                      loading='lazy'
                      alt=""
                      srcSet=""
                      className="lg:scale-110"
                    />
                  </div>
                  <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                        {t("home_how_it_works_step_2")}
                      </span>
                      <span className="text-3xl font-semibold">
                        {t("home_how_it_works_provide_details")}
                      </span>
                    </div>
                    <span className="block text-2xl text-justify">
                      <span className="font-bold">
                        {t("home_how_it_works_share_skills_experience_one")}
                      </span>{" "}
                      {t("home_how_it_works_share_skills_experience_two")}
                    </span>
                  </div>
                  <img
                    className="absolute right-5 -top-3/4 hidden xl:block"
                    src={OneTwoArrow}
                    loading='lazy'
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="relative flex items-center justify-evenly">
                  <img
                    className="absolute left-5 -top-3/4 hidden xl:block"
                    src={TwoThreeArrow}
                    loading='lazy'
                    alt=""
                    srcSet=""
                  />
                  <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                        {t("home_how_it_works_step_3")}
                      </span>
                      <span className="text-3xl font-semibold">
                        {t("home_how_it_works_receive_jobs")}
                      </span>
                    </div>
                    <span className="block text-2xl text-justify">
                      <span className="font-bold">
                        {t("home_how_it_works_get_job_matches_one")}
                      </span>
                      {t("home_how_it_works_get_job_matches_two")}
                    </span>
                  </div>
                  <div className="drop-shadow-2xl hidden lg:block">
                    <img
                      src={StepThreeImage}
                      loading='lazy'
                      alt=""
                      srcSet=""
                      className="lg:scale-110"
                    />
                  </div>
                </div>
                <div className="relative flex items-center justify-evenly">
                  <div className="drop-shadow-2xl hidden lg:block">
                    <img
                      src={StepFourImage}
                      loading='lazy'
                      alt=""
                      srcSet=""
                      className="lg:scale-110"
                    />
                  </div>
                  <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                        {t("home_how_it_works_step_4")}
                      </span>
                      <span className="text-3xl font-semibold">
                        {t("home_how_it_works_choose_get_hired")}
                      </span>
                    </div>
                    <span className="block text-2xl text-justify">
                      <span className="font-bold">
                        {t("home_how_it_works_select_job_apply_one")}
                      </span>{" "}
                      {t("home_how_it_works_select_job_apply_two")}
                    </span>
                  </div>
                  <img
                    className="absolute right-5 -top-3/4 hidden xl:block"
                    src={ThreeFourArrow}
                    loading='lazy'
                    alt=""
                    srcSet=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
          href={`https://jobs.kaigal.com/?lang=${i18n.language}&utm_source=website&utm_medium=get_started_${i18n.language}&utm_campaign=candidate_${i18n.language}`}
        target="_blank"
        rel="noreferrer"
        className="bg-orange py-4 rounded-lg text-white px-24 mt-16 inline-block hover:bg-green font-semibold"
      >
        {t("home_how_it_works_get_started")}
      </a>
    </section>
  );
}
