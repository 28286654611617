import Timeline from "../components/Timeline";
import ExploreOurGallery from "./about/ExploreOurGallery";
import Hero from "./about/Hero";
import MeetOurTeam from "./about/MeetOurTeam";
import MissionAndVission from "./about/MissionAndVission";

export default function About() {
  return (
    <>
      <Hero />
      <Timeline/>
      <MeetOurTeam />
      <MissionAndVission />
      <ExploreOurGallery />
    </>
  );
}
