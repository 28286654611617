import BgVector from "../../assets/home/homePresenceOfKaigal/bg-vector.svg";
import { useTranslation } from "react-i18next";
import KaigalPresenceInIndia from "../../assets/home/homePresenceOfKaigal/india.webp";

export default function PresenceOfKaigal() {
  const { t } = useTranslation();
  return (
    <section
      className={`bg-[#F5F5F5] p-8 sm:p-16 bg-[length:200px] md:bg-[length:400px] lg:md:bg-[length:500px] bg-no-repeat bg-left-bottom`}
      style={{ backgroundImage: `url(${BgVector})` }}
    >
      <h1 className="text-5xl text-orange font-semibold">
        {t("home_kaigal_presence")}
      </h1>
      <p className="p-1 text-lg font-semibold sm:me-10 text-[#5C5B5B]/70">
        {t("home_kaigal_presence_connecting_jobs_across_india")}
      </p>
      <div className="sm:m-12 lg:px-16 space-y-10 flex justify-center">
        <img
          src={KaigalPresenceInIndia}
          loading='lazy'
          width="366px"
          height="366px"
          className="w-full lg:w-[75dvh] hover:drop-shadow-2xl"
          alt="Kaigal Presence in India"
        />
      </div>
    </section>
  );
}
