import Eye from "../../assets/about/missionandvision/eye.svg";
import Target from "../../assets/about/missionandvision/target.svg";
import whiteRect from "../../assets/about/missionandvision/white-rect.svg";
import greenRect from "../../assets/about/missionandvision/green-rect.svg";
import { useTranslation } from "react-i18next";
export default function MissionAndVission() {
  const { t } = useTranslation();
  return (
    <section className="mt-12 sm:mt-20 bg-slate-200 py-16">
      <h1 className="text-5xl text-orange font-semibold block break-words">
        {t("about_our_mission_and_vision")}&nbsp;
        {t("about_our_mission_and_vision_vision")}&nbsp;
        {t("about_our_mission_and_vision_and")}&nbsp;
        {t("about_our_mission_and_vision_mission")}
      </h1>
      <div className="border border-[#EE6A29] border-dashed flex flex-col sm:flex-row m-16 rounded-lg bg-white">
        <div className="flex flex-col gap-2 justify-center items-center p-8 sm:w-1/2">
          <img src={Eye} alt="" loading='lazy' />
          <span className="text-orange font-semibold">
            {t("about_our_mission_and_vision_vision")}
          </span>
          <p className="sm:w-1/2">
            {t("about_our_mission_and_vision_connecting")}
          </p>
        </div>
        <div className="flex flex-col gap-2 bg-blue scale-125 justify-between sm:justify-around items-center rounded-lg">
          <img
          loading='lazy'
            src={whiteRect}
            alt=""
            className="w-3/4 self-start rounded-tl-lg sm:rounded-none"
          />
          <p className="text-white font-semibold text-2xl p-4">
            {t("about_our_mission_and_vision_m_v")}
          </p>
          <img
          loading='lazy'
            src={greenRect}
            alt=""
            className="w-3/4 self-end rounded-br-lg sm:rounded-none"
          />
        </div>
        <div className="flex flex-col gap-2 justify-center items-center p-8 sm:w-1/2">
          <img src={Target} alt="" loading='lazy' />
          <span className="text-orange font-semibold">
            {t("about_our_mission_and_vision_mission")}
          </span>
          <p className="sm:w-1/2">
            {t("about_our_mission_and_vision_empowers")}
          </p>
        </div>
      </div>
    </section>
  );
}
