import HeroImage from "../assets/tac/hero.webp";
import { useTranslation } from "react-i18next";

export default function TAC() {
  const { t } = useTranslation();
  return (
    <section className="container w-11/12 mx-auto text-left m-auto mt-24 py-10">
      <div className="relative mb-12">
        <img src={HeroImage} className="w-full" alt="" srcset="" loading='lazy' />
        <div className="absolute bottom-0 p-12 left-0 text-white w-full bg-gradient-to-b from-gray-100/[0] to-slate-900/[0.75] rounded-t-lg rounded-[16px]">
          <div className="flex flex-col gap-1">
            <h1 className="font-bold text-3xl">{t("tac_tac")}</h1>
            <h2>{t("tac_please_read")}</h2>
          </div>
        </div>
      </div>
      <p className="my-4">{t("tac_by_using")}</p>
      <p className="my-4">{t("tac_you_also")}</p>
      <p className="my-4">{t("tac_if_we")}</p>
      <p className="my-4">{t("tac_we_reserve")}</p>
      <p className="my-4">{t("tac_we_care")}</p>
      <h2 className="font-bold text-3xl">{t("tac_key_takeways")}</h2>
      <p className="my-4">{t("tac_we_will_not")}</p>
      <p className="my-4">{t("tac_we_use")}</p>
      <p className="my-4">{t("tac_we_will_never")}</p>
      <p className="my-4">{t("tac_we_will_employ")}</p>
      <p className="font-bold text-3xl my-4">{t("tac_data_that")}</p>

      <span className="my-4 font-bold">{t("tac_job_seeker")}</span>

      <p className="my-4">{t("tac_kaigal_collects")}</p>
      <p className="my-4">{t("tac_you_have_option")}</p>
      <p className="my-4">{t("tac_if_you")}</p>
      <span className="my-4 font-bold ">{t("tac_client_info")}</span>

      <p className="my-4">{t("tac_to_use")}</p>
      <span className="my-4 font-bold">{t("tac_additional_information")}</span>
      <p className="my-4">{t("tac_additional_use")}</p>

      <span className="my-4 font-bold">{t("tac_cookies")}</span>

      <p className="my-4">{t("tac_like_most")}</p>
      <p className="my-4">{t("tac_use_of")}</p>
      <span className="my-4 font-bold text-3xl">
        {t("tac_information_usage")}
      </span>

      <p className="my-4">{t("tac_kaigal")}</p>
      <p className="my-4">{t("tac_havE_option")}</p>
      <p className="my-4">{t("tac_choose_to_use")}</p>
      <p className="my-4">{t("tac_use_your_personal")}</p>
      <p className="my-4">{t("tac_to_provide")}</p>
      <p className="my-4">{t("tac_to_meet")}</p>
      <p className="my-4">{t("tac_provide_relation")}</p>
      <p className="my-4">{t("tac_we_will_not_release")}</p>
      <p className="my-4">{t("tac_to_conduct")}</p>
      <p className="my-4">{t("tac_you_may_receive")}</p>
      <p className="my-4">{t("tac_acknowledge")}</p>
      <span className="my-4 font-bold text-3xl">
        {t("tac_customer_testimonials")}
      </span>

      <p className="my-4">{t("tac_we_post_customer")}</p>
      <span className="my-4 font-bold text-3xl">
        {t("tac_information_sharing")}
      </span>

      <p className="my-4">{t("tac_we_not_rent")}</p>
      <p className="my-4">{t("tac_we_reserve_right")}</p>
      <p className="my-4">{t("tac_we_collect")}</p>
      <p className="my-4">{t("tac_on_website")}</p>
      <p className="my-4">{t("tac_time_creating")}</p>
      <p className="my-4">{t("tac_permitted")}</p>
      <p className="my-4">{t("tac_granted")}</p>
      <p className="my-4">{t("tac_third_parties")}</p>
      <p className="my-4">{t("tac_share_your_info")}</p>
      <span className="my-4 font-bold text-3xl">{t("tac_how_we_comply")}</span>

      <p className="my-4">{t("tac_utilize")}</p>
      <p className="my-4">{t("tac_no_company")}</p>
      <p className="my-4">{t("tac_we_recommend")}</p>
      <span className="my-4 font-bold">{t("tac_privacy_audits")}</span>

      <p className="my-4">{t("tac_we_conduct")}</p>
      <span className="my-4 font-bold">{t("tac_referral_program")}</span>

      <p className="my-4">{t("tac_choose_referral")}</p>
      <p className="my-4">{t("tac_friend_contact")}</p>
      <p className="my-4">{t("tac_agree_paying")}</p>
      <span className="my-4 font-bold">{t("tac_your_choice")}</span>

      <p className="my-4">{t("tac_we_offer")}</p>
      <span className="font-bold text-3xl">{t("tac_amending_correcting")}</span>
      <p className="my-4">{t("tac_personality")}</p>
      <span className="font-bold">{t("tac_questions")}</span>
      <p className="my-4">{t("tac_if_questions")}</p>
    </section>
  );
}
