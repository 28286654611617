import React from "react";
import "photoswipe/dist/photoswipe.css"; // Importing Photoswipe CSS
import { Gallery, Item } from "react-photoswipe-gallery"; // Correct import
import { useTranslation } from "react-i18next";
import One from "../../assets/about/gallery/one.webp";
import two from "../../assets/about/gallery/two.webp";
import three from "../../assets/about/gallery/three.webp";
import four from "../../assets/about/gallery/four.webp";
import five from "../../assets/about/gallery/five.webp";
import six from "../../assets/about/gallery/six.webp";
import seven from "../../assets/about/gallery/seven.webp";
import eight from "../../assets/about/gallery/eight.webp";
import nine from "../../assets/about/gallery/nine.webp";
import ten from "../../assets/about/gallery/ten.webp";
import eleven from "../../assets/about/gallery/eleven.webp";
import twelve from "../../assets/about/gallery/tweleve.webp";
import thirteen from "../../assets/about/gallery/thirteen.webp";
import fourteen from "../../assets/about/gallery/fourteen.webp";
import fifteen from "../../assets/about/gallery/fifteen.webp";
import sixteen from "../../assets/about/gallery/sixteen.webp";
import seventeen from "../../assets/about/gallery/seventeen.webp";

export default function ExploreOurGallery() {
  const { t } = useTranslation();

  const images = [
    { src: One, alt: "One" },
    { src: two, alt: "Two" },
    { src: three, alt: "Three" },
    { src: four, alt: "Four" },
    { src: five, alt: "Five" },
    { src: six, alt: "Six" },
    { src: seven, alt: "Seven" },
    { src: eight, alt: "Eight" },
    { src: nine, alt: "Nine" },
    { src: ten, alt: "Ten" },
    { src: eleven, alt: "Eleven" },
    { src: twelve, alt: "Twelve" },
    { src: thirteen, alt: "Thirteen" },
    { src: fourteen, alt: "Fourteen" },
    { src: fifteen, alt: "Fifteen" },
    { src: sixteen, alt: "Sixteen" },
    { src: seventeen, alt: "Seventeen" },
  ];
  const layoutConfig = [
    { cols: 2, smCols: 4, range: [0, 4] },
    { cols: 1, smCols: 2, range: [4, 6] },
    { cols: 1, smCols: 4, range: [6, 8], span: 3 },
    { cols: 1, smCols: 2, range: [8, 10] },
    { cols: 1, smCols: 2, range: [10, 12] },
    { cols: 1, smCols: 2, range: [12, 14] },
    { cols: 1, smCols: 2, range: [14, 16] },
    { cols: 1, smCols: 1, range: [16, 17] },
  ];
  return (
    <section className="my-12 sm:my-20" id="gallery">
      <h1 className="text-3xl sm:text-5xl text-orange font-semibold block">
        {t("about_explore_our_gallery_explore_our")}{" "}
        {t("about_explore_our_gallery_gallery")}
      </h1>
      {/* <Gallery
        // options={{
        //   bgOpacity: 0.85,
        //   showHideOpacity: true,
        // }}
      >
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(0, 4).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref} // Required to attach the ref for opening the lightbox
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open} // Clicking the image opens the lightbox
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(4, 6).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(6, 8).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full sm:col-span-3"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(8, 10).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(10, 12).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(12, 14).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(14, 16).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
        <div className="grid grid-cols-1 gap-4 sm:gap-8 m-4 sm:m-10">
          {images.slice(16).map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.src}
              width="1024"
              height="1024"
            >
              {({ ref, open }) => (
                <img
                  ref={ref}
                  src={image.src}
                  alt={image.alt}
                  className="w-full"
                  onClick={open}
                />
              )}
            </Item>
          ))}
        </div>
      </Gallery> */}
      <Gallery
        options={{
          bgOpacity: 0.75, // Darken background
          maxSpreadZoom: 1, // Avoid over-enlarging images
          fullscreenEl: true, // Enable fullscreen mode
        }}
      >
        {layoutConfig.map((config, idx) => (
          <div
            key={idx}
            className={`grid grid-cols-${config.cols} sm:grid-cols-${config.smCols} gap-4 sm:gap-8 m-4 sm:m-10`}
          >
            {images
              .slice(config.range[0], config.range[1])
              .map((image, index) => (
                <Item
                  key={index}
                  original={image.src}
                  thumbnail={image.src}
                  width="1024"
                  height="1024"
                >
                  {({ ref, open }) => (
                    // <img ref={ref} src={image.src} alt={image.alt} className="w-full" onClick={open} />
                    <img
                      ref={ref}
                      src={image.src}
                      alt={image.alt}
                      loading='lazy'
                      // className="w-full"
                      className={`w-full ${config.range[0] === 6 && index === 1 ? "sm:col-span-3" : " h-full"}`}
                      // className={`w-full ${config.span ? `sm:col-span-${config.span}` : ""}`}
                      // className="w-auto h-[90dvh] mx-auto object-contain"
                      onClick={open}
                      aria-label={`Open image ${image.alt}`}
                    />
                  )}
                </Item>
              ))}
          </div>
        ))}
      </Gallery>
    </section>
  );
}
