import TrustedBg from "../../assets/bgvectorTrustedby.svg";
import GreenHand from "../../assets/home/trustedBy/greenHand.webp";
import OrangeHand from "../../assets/home/trustedBy/orangeHand.webp";
import imageOne from "../../assets/home/trustedBy/companies/ecom-express.webp";
import imageTwo from "../../assets/home/trustedBy/companies/tvs-train-services.webp";
import imageThree from "../../assets/home/trustedBy/companies/swelect.webp";
import imageFour from "../../assets/home/trustedBy/companies/ekart.webp";
import imageFive from "../../assets/home/trustedBy/companies/esandhai.webp";
import imageSix from "../../assets/home/trustedBy/companies/first-cry.webp";
import imageSeven from "../../assets/home/trustedBy/companies/flipkart.webp";
import imageEight from "../../assets/home/trustedBy/companies/ifb.webp";
import imageNine from "../../assets/home/trustedBy/companies/kotak-life.webp";
import imageTen from "../../assets/home/trustedBy/companies/kpr.webp";
import imageEleven from "../../assets/home/trustedBy/companies/lt.webp";
import imageTweleve from "../../assets/home/trustedBy/companies/mabia.webp";
import imageThirteen from "../../assets/home/trustedBy/companies/pongalursas.webp";
import imageFourteen from "../../assets/home/trustedBy/companies/ramraj.webp";
import imageFifteen from "../../assets/home/trustedBy/companies/royal-enfi.webp";
import imageSixteen from "../../assets/home/trustedBy/companies/shriram.webp";
import imageSeventeen from "../../assets/home/trustedBy/companies/suzuki.webp";
import imageNineteen from "../../assets/home/trustedBy/companies/swelect.webp";
import imageTwenty from "../../assets/home/trustedBy/companies/thangamayil.webp";
import imageTwentyOne from "../../assets/home/trustedBy/companies/topi-vappa-biriyani.webp";
import imageTwentyTwo from "../../assets/home/trustedBy/companies/tvs-train-services.webp";
import imageTwentyThree from "../../assets/home/trustedBy/companies/yamaha.webp";
import imageTwentyFour from "../../assets/home/trustedBy/companies/zudio.webp";
import imageTwentyFive from "../../assets/home/trustedBy/companies/you-can-be-part-of.webp";
import { useTranslation } from "react-i18next";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { motion } from "framer-motion";

export default function TrustedBy() {
  const { t } = useTranslation();
  const images = [
    imageOne,
    imageTwo,
    imageThree,
    imageFour,
    imageFive,
    imageSix,
    imageSeven,
    imageEight,
    imageNine,
    imageTen,
    imageEleven,
    imageTweleve,
    imageThirteen,
    imageFourteen,
    imageFifteen,
    imageSixteen,
    imageSeventeen,
    imageNineteen,
    imageTwenty,
    imageTwentyOne,
    imageTwentyTwo,
    imageTwentyThree,
    imageTwentyFour,
    imageTwentyFive,
  ];
  const chunkImages = (arr, size) =>
    arr.reduce(
      (chunks, _, i) =>
        i % size === 0 ? [...chunks, arr.slice(i, i + size)] : chunks,
      []
    );
  const isDesktopSmall = window.innerWidth <= 1023; // Detect if the screen width is less than or equal to 768px
  const isMobile = window.innerWidth <= 767; // Detect if the screen width is less than or equal to 768px
  const chunkSize = isDesktopSmall ? (isMobile ? 2 : 4) : 6; // 3 images per slide for mobile, 6 for desktop
  const chunkedImages = chunkImages(images, chunkSize);
  const responsiveSettings = [
    // {
    //     breakpoint: 800,
    //     settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3
    //     }
    // },
    // {
    //     breakpoint: 500,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //     }
    // }
  ];
  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };
  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    ),
  };
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };
  return (
    <section className="bg-[#F1F1F1]/50 pt-8">
      <div
        style={{ "--home--trusted-by-orange-sm-bg": `url(${OrangeHand})` }}
        className={`sm:h-full bg-no-repeat md:bg-[top_3%_right_3%] md:bg-[image:var(--home--trusted-by-orange-sm-bg)]`}
      >
        <div
          style={{ "--home--trusted-by-green-sm-bg": `url(${GreenHand})` }}
          className={`sm:h-full bg-no-repeat md:bg-[left_3%_bottom_3%] md:bg-[image:var(--home--trusted-by-green-sm-bg)]`}
        >
          <div
            className={`p-8 sm:p-16 bg-cover bg-no-repeat bg-left-top lg:min-h-min xl:h-[95dvh] 2xl:h-[90dvh]`}
            style={{ backgroundImage: `url(${TrustedBg})` }}
          >
            <h5 className="text-5xl text-orange font-semibold mb-5">
              {t("home_ways_to_register_trusted_by_trusted")}{" "}
              <span className="text-black">
                {t("home_ways_to_register_trusted_by_by")}
              </span>
            </h5>
            <span className="text-lg font-semibold text-[#5C5B5B]/70">
              {t("home_ways_to_register_trusted_top_companies")}
            </span>
            <Slide
              slidesToScroll={1}
              slidesToShow={1}
              indicators={true}
              responsive={responsiveSettings}
              {...properties}
            >
              {chunkedImages.map((chunk, index) => (
                <div key={index} className="each-slide-effect mt-10 sm:mt">
                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="grid sm:grid-cols-2 lg:grid-cols-3 px-4 lg:px-14 gap-14 sm:gap-24 lg:mt-20 m-4 sm:m-12 place-content-center place-items-center"
                  >
                    {chunk.map((img, idx) => (
                      <motion.img
                        key={idx}
                        src={img}
                        alt={`Logo ${idx + 1}`}
                        className="w-[200px]"
                        variants={itemVariants}
                      />
                    ))}
                  </motion.div>
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
}
