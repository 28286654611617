import { useTranslation } from "react-i18next";
export default function Hero() {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-5xl text-orange font-semibold block mt-24 sm:mt-30">
        {t("about_hero_our_story")}
      </h1>
      <span className="block p-1 text-lg font-semibold text-[#5C5B5B]/70 sm:w-1/2 text-center mx-auto">
        {t("about_hero_story_behind")}
      </span>
    </>
  );
}
