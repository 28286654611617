import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftRectVector from "../../assets/home/moreAboutKaigal/bg-pattern-left.webp";
import RightRectVector from "../../assets/more-about-kaigal/bg-pattern-right.svg";
import LeftGroupVector from "../../assets/more-about-kaigal/group-left.svg";
import RightGroupVector from "../../assets/more-about-kaigal/group-right.svg";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";

export default function MoreAboutKaigal() {
  const { t, i18n } = useTranslation();
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videos, setVideos] = useState([]);

  // Mapping language codes to playlist IDs
  const playlistMapping = {
    en: "PLXL7zYIKaaG-gCnEzPapaX2iyAzX-YbNY", // English
    hi: "PLXL7zYIKaaG9Yhc5li-We9vltwZtcQMAP", // Hindi
    ta: "PLXL7zYIKaaG9A9uePkfwLtwuX6aMQkHRV", // Tamil
    ml: "PLKokO6EZ3pDPMlIjYIT_SHO6TCMpaOYIQ", // Malayalam
    bn: "PLKokO6EZ3pDNSoXyjVz0vDLsHaxP-kfhb", // Bengali
    gu: "PLKokO6EZ3pDM_2BEfW5WRhjT42b2DMx-l", // Gujarati
    mr: "PLKokO6EZ3pDNW7wdDECM-8Dlp8rh7DhGa", // Marathi
    kn: "PLKokO6EZ3pDOPa4WWS9dYtlp8JUUmnKxM", // Kannada
    or: "PLKokO6EZ3pDNfgOPGe0y_8tsV46TGWtsr", // Odia
    te: "PLXL7zYIKaaG8I9PATLCwOxDk0Ld-0-w6c", // Telugu
    as: "PLKokO6EZ3pDNJx0FWPX1528Aq_lxSfME5", // Assamese
  };

  const API_KEY = "AIzaSyA-5gV2g6rkLK5RXhm1jv_4wncAibATf4k"; // YouTube API Key

  useEffect(() => {
    const langCode = i18n.language; // Get current language from i18n
    const playlistId = playlistMapping[langCode] || playlistMapping["en"]; // Fallback to English

    const fetchPlaylistVideos = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&key=${API_KEY}&maxResults=10`
        );
        const data = await response.json();

        if (data.items) {
          const videoIds = data.items.map(
            (item) => item.snippet.resourceId.videoId
          );
          setVideos(videoIds);
          setCurrentVideo(videoIds[0]); // Set the first video as the default
        } else {
          console.error("No videos found in the playlist.");
        }
      } catch (error) {
        console.error("Error fetching playlist videos:", error);
      }
    };

    fetchPlaylistVideos();
    // eslint-disable-next-line
  }, [i18n.language]);

  const sliderSettings = {
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
    ],
  };

  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
    },
  };

  const handleThumbnailClick = (videoId) => {
    setCurrentVideo(videoId);
  };

  return (
    <section className="bg-blue text-white py-12 lg:min-h-dvh" id="more-about-kaigal">
      <div
        className="relative bg-no-repeat bg-right-top"
        style={{ backgroundImage: `url('${RightRectVector}')` }}
      >
        <h1 className="text-5xl font-semibold text-center mb-4">
          {t("home_more_about_kaigal")}
        </h1>
        <p className="text-lg font-semibold text-[#FFFFFF]/60 text-center mb-8">
          {t("home_more_about_kaigal_welcome_message")}
        </p>
        <div
          className="relative bg-no-repeat bg-left-bottom"
          style={{ backgroundImage: `url('${LeftRectVector}')` }}
        >
          <div
            className="relative bg-no-repeat bg-left-top"
            style={{ backgroundImage: `url('${LeftGroupVector}')` }}
          >
            <div
              className="relative bg-no-repeat bg-right-bottom"
              style={{ backgroundImage: `url('${RightGroupVector}')` }}
            >
              <div className="mb-5">
                {currentVideo && (
                  <YouTube
                    videoId={currentVideo}
                    opts={youtubeOptions}
                    className="aspect-video w-11/12 rounded justify-self-center sm:w-3/6"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-10/12 justify-self-center">
          <Slider {...sliderSettings}>
            {videos.map((videoId, index) => (
              <div
                key={index}
                onClick={() => handleThumbnailClick(videoId)}
                className="thumbnail cursor-pointer"
              >
                <img
                loading='lazy'
                  src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                  alt={`Thumbnail ${index + 1}`}
                  className="aspect-video justify-self-center w-10/12"
                  style={{
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
