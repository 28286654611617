// import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./layout/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./layout/Footer";
import Client from "./pages/Client";
import TAC from "./pages/TAC";
import LanguageSelector from "./components/LanguageSelector";
import Chatbot from "./components/chatbot";

function App() {
  return (
    // <BrowserRouter  basename="/kaigal">
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Header />
      <LanguageSelector />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/client" element={<Client />} />
        <Route path="/PrivacyPolicy" element={<TAC />} />
      </Routes>
      {/*<Chatbot />*/}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
