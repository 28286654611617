import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export default function SuccessStats() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  // Animation variants with responsive design
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.9, y: 30 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <section className="m-8 sm:m-16 block" ref={ref}>
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: -20 },
        }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="text-5xl font-semibold mb-8 text-center"
      >
        <span className="text-blue">{t("home_success_stats_success")}</span>{" "}
        <span className="text-orange">{t("home_success_stats_stats")}</span>
      </motion.h2>

      <motion.div
        className="flex flex-col sm:flex-row justify-around items-center font-semibold gap-8"
        initial="hidden"
        animate={controls}
        variants={{
          visible: { transition: { staggerChildren: 0.3 } },
        }}
      >
        {/* Total Clients */}
        <motion.span
          variants={itemVariants}
          className="flex flex-col items-center"
        >
          {/* <span className="text-[40px]"> */}
          <CountUp enableScrollSpy start={0} end={4} duration={3} suffix=" L+">
            {({ countUpRef }) => (
              <div>
                <span className="text-[40px]" ref={countUpRef} />
              </div>
            )}
          </CountUp>
          {/* </span> */}
          <span className="text-[32px] text-green">
            {t("home_success_stats_candidates")}
          </span>
        </motion.span>

        {/* Candidates */}
        <motion.span
          variants={itemVariants}
          className="flex flex-col items-center"
        >
          {/* <span className="text-[40px]"> */}
          <CountUp enableScrollSpy start={0} end={22} duration={3} suffix=" K+">
            {({ countUpRef }) => (
              <div>
                <span className="text-[40px]" ref={countUpRef} />
              </div>
            )}
          </CountUp>
          {/* </span> */}
          <span className="text-[32px] text-green">
            {t("home_success_stats_businesses")}
          </span>
        </motion.span>

        {/* Interviews */}
        <motion.span
          variants={itemVariants}
          className="flex flex-col items-center"
        >
          {/* <span className="text-[40px]"> */}
          <CountUp
            enableScrollSpy
            start={0}
            end={2}
            duration={3}
            suffix=" L+"
          >
            {({ countUpRef }) => (
              <div>
                <span className="text-[40px]" ref={countUpRef} />
              </div>
            )}
          </CountUp>
          {/* </span> */}
          <span className="text-[32px] text-green">
            {t("home_success_stats_opportunities")}
          </span>
        </motion.span>
      </motion.div>
    </section>
  );
}
