import ClientHowItWorks from "./client/ClientHowItWorks";
import Hero from "./client/Hero";
import Investors from "./client/Investors";
import Services from "./client/Services";
import MoreAboutKaigal from "./home/MoreAboutKaigal";
import PresenceOfKaigal from "./home/PresenceOfKaigal";
import SuccessStats from "./home/SuccessStats";
import WayToRegister from "./home/WayToRegister";
import WhyChooseUs from "./home/WhyChooseUs";

export default function Client() {
  return (
    <>
      <Hero />
      <Investors />
      <SuccessStats />
      <WhyChooseUs type="client" />
      <Services />
      <WayToRegister type='client' />
      <ClientHowItWorks />
      <PresenceOfKaigal />
      <MoreAboutKaigal />
    </>
  );
}
