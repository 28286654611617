import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import DownIcon from "../assets/icons/down.svg";
import { motion, AnimatePresence } from "framer-motion";
import i18n from "../i18n";

const languages = [
  { code: "en", label: "header_lang_english" },
  { code: "hi", label: "header_lang_hindi" },
  { code: "ta", label: "header_lang_tamil" },
  // { code: "ml", label: "header_lang_malayalam" },
  // { code: "kn", label: "header_lang_kannada" },
  { code: "te", label: "header_lang_telugu" },
  // { code: "or", label: "header_lang_odia" },
  // { code: "bn", label: "header_lang_bengali" },
  // { code: "mr", label: "header_lang_marathi" },
  // { code: "gu", label: "header_lang_gujarati" },
  // { code: "as", label: "header_lang_assamese" },
];
i18n.on("languageChanged", (lng) => {
  if (!languages.some((lang) => lang.code === lng)) {
    console.error(`Missing translation file for language: ${lng}`);
  }
});

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((lang) => lang.code === i18n.language) || languages[0]
  );
  // Use useEffect to update selectedLanguage when i18n.language changes
  useEffect(() => {
    setSelectedLanguage(
      languages.find((lang) => lang.code === i18n.language) || languages[0]
    );
  }, [i18n.language]); // Dependency on i18n.language

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang.code);
    localStorage.setItem("preferredLanguage", lang.code);
  };

  return (
    <div className="relative inline-block w-36">
      <Listbox value={selectedLanguage} onChange={handleLanguageChange}>
        <ListboxButton className="flex items-center justify-between w-full px-3.5 py-[9px] text-white font-bold bg-orange rounded-lg text-lg">
          {t(`${selectedLanguage.label}`)}
          <img src={DownIcon} alt="Dropdown icon" className="ml-2 w-4 h-4" loading="lazy" />
        </ListboxButton>

        <AnimatePresence>
          <ListboxOptions
            as={motion.ul}
            className="absolute w-full mt-1 overflow-hidden rounded-lg shadow-lg"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {languages.map((lang, index) => (
              <ListboxOption
                as={motion.li}
                key={lang.code}
                value={lang}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.05 }}
                className={({ active }) =>
                  `cursor-pointer select-none py-2 px-4 font-medium bg-orange ${
                    active
                      ? "bg-orange-100 text-white font-black"
                      : "bg-white text-black"
                  }`
                }
              >
                {t(lang.label)}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </AnimatePresence>
      </Listbox>
    </div>
  );
};

export default LanguageSwitcher;
