import { Slide } from "react-slideshow-image";
import { motion } from "framer-motion";
import "react-slideshow-image/dist/styles.css"; // Import carousel styles
import Mail from "../../assets/about/meetourteam/mail.svg";
import LinkedIn from "../../assets/about/meetourteam/linkedin.svg";
import LeftBg from "../../assets/about/meetourteam/left.svg";
import RightBg from "../../assets/about/meetourteam/right.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import teamJson from "../../team/team.json";
import bala from "../../team/images/Bala.webp";
import Chandu from "../../team/images/Chandu.webp";
import Deepthi from "../../team/images/Deepthi.webp";
import Dharshika from "../../team/images/Dharshika.webp";
import MadhanSasi from "../../team/images/Madhan-Sasi.webp";
import MathanSrinivasan from "../../team/images/Mathan-Srinivasan.webp";
import Mithra from "../../team/images/Mithra.webp";
import PavithraRavi from "../../team/images/Pavithra-Ravi.webp";
import Priyanga from "../../team/images/Priyanga.webp";
import RavinSomi from "../../team/images/Ravin-Somi.webp";
import Revathi from "../../team/images/Revathi.webp";
import Salma from "../../team/images/Salma.webp";
import Sangeeth from "../../team/images/Sangeeth.webp";
import SenthilManikandan from "../../team/images/Senthil-Manikandan.webp";

const teamImages = {
  Bala: bala,
  Chandu: Chandu,
  Deepthi: Deepthi,
  Dharshika: Dharshika,
  MadhanSasi: MadhanSasi,
  MathanSrinivasan: MathanSrinivasan,
  Mithra: Mithra,
  PavithraRavi: PavithraRavi,
  Priyanga: Priyanga,
  RavinSomi: RavinSomi,
  Revathi: Revathi,
  Salma: Salma,
  Sangeeth: Sangeeth,
  SenthilManikandan: SenthilManikandan,
}

export default function MeetOurTeam() {
  const { t } = useTranslation();
  const [teamData, setTeamData] = useState([]);
  const [chunkSize, setChunkSize] = useState(7); // Default chunk size for desktop

  // Fetch team data on component mount
  useEffect(() => {
    // fetch(
    //   "https://raw.githubusercontent.com/prasathvjkmr/kaigal-translation/main/team/team.json"
    // )
    //   .then((response) => response.json())
    //   .then((data) => setTeamData(data));
    setTeamData(teamJson);
  }, []);

  // Adjust chunk size based on screen width
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setChunkSize(isMobile ? 2 : 7); // 3 for mobile, 6 for desktop
    };

    // Initial check and event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to split data into chunks
  const getChunks = (data, size) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += size) {
      chunks.push(data.slice(i, i + size));
    }
    return chunks;
  };

  // Create chunks based on the current chunk size
  const teamChunks = getChunks(teamData, chunkSize);

  if (!teamData.length) return <div>Loading...</div>;
  const responsiveSettings = [
    // {
    //     breakpoint: 800,
    //     settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3
    //     }
    // },
    // {
    //     breakpoint: 500,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //     }
    // }
  ];
  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };
  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    ),
  };

  return (
    <section className="mt-12 sm:mt-20">
      <div
        className="bg-no-repeat bg-left-bottom"
        style={{ backgroundImage: `url(${LeftBg})` }}
      >
        <div
          className="bg-no-repeat bg-right-top"
          style={{ backgroundImage: `url(${RightBg})` }}
        >
          {/* Section Heading */}
          <h1 className="text-5xl text-orange font-semibold text-center">
            {t("about_meet_our_team")}
          </h1>
          <span className="block p-1 text-lg font-semibold text-[#5C5B5B]/70 sm:w-1/2 text-center mx-auto">
            {t("about_meet_our_team_one")} {t("about_meet_our_team_two")}
          </span>

          {/* Team Carousel */}
          <div className="mt-4 sm:mt-8 w-[96%] mx-auto">
            <Slide
              // indicators={true}
              // prevArrow={<button className="text-gray-500 hover:text-gray-700">❮</button>}
              // nextArrow={<button className="text-gray-500 hover:text-gray-700">❯</button>}
              indicators={true}
              responsive={responsiveSettings}
              {...properties}
            >
              {teamChunks.map((chunk, index) => (
                <div
                  key={`chunk-${index}`}
                  className="flex flex-wrap justify-center items-center gap-6 sm:gap-10 sm:gap-16 my-4 sm:my-8 container mx-auto"
                >
                  {chunk.map((member) => (
                    <motion.div
                      key={member.id || member.name} // Ensure unique key
                      className="bg-green text-white w-3/5 sm:w-[18%] flex flex-col justify-center items-center p-5 gap-3 rounded-[30px]"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src={teamImages[member.image]}
                        alt={member.name}
                        loading='lazy'
                        // className="rounded-full w-24 h-24"
                      />
                      <div>
                        <h1 className="font-medium text-lg truncate ">
                          {t(member.name)}
                        </h1>
                        <p className="opacity-75 font-normal text-sm truncate ">
                          {t(member.designation)}
                        </p>
                      </div>
                      <p className="flex gap-5">
                        <a href={`mailto:${member.mail}`} className="block">
                          <img
                            className="scale-125"
                            src={Mail}
                            loading='lazy'
                            alt="Mail Icon"
                          />
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={member.linkedin}
                          className="block"
                        >
                          <img
                            className="scale-125"
                            src={LinkedIn}
                            loading='lazy'
                            alt="LinkedIn Icon"
                          />
                        </a>
                      </p>
                    </motion.div>
                  ))}
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
}
