import React from "react";
import FrequentlyAskedQuestions from "./home/FrequentlyAskedQuestions";
import Hero from "./home/Hero";
import HowItWorks from "./home/HowItWorks";
import MoreAboutKaigal from "./home/MoreAboutKaigal";
import Partners from "./home/Partners";
import PresenceOfKaigal from "./home/PresenceOfKaigal";
import SuccessStats from "./home/SuccessStats";
import TrustedBy from "./home/TrustedBy";
import WayToRegister from "./home/WayToRegister";
import WhyChooseUs from "./home/WhyChooseUs";

export default function Home() {
  return (
    <>
      <Hero />
      <SuccessStats />
      <WhyChooseUs type="user"/>
      <WayToRegister type="user" />
      <TrustedBy />
      <HowItWorks />
      <PresenceOfKaigal />
      <Partners />
      <MoreAboutKaigal />
      <FrequentlyAskedQuestions />
    </>
  );
}
