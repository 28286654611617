import React from "react";
import { motion } from "framer-motion";

// Reusable Card Component
const AnimatedCard = ({
  imgSrc,
  title,
  content,
  type,
  index,
  objectPosition,
}) => {
  // Animation variants for the cards
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9, y: 50 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 25,
      },
    },
  };

  return (
    <motion.div
      className={`flex flex-col justify-center items-center relative ${
        type === "client" ? "basis-[30%]" : ""
      } group hover:bg-gradient-to-t hover:rounded-[30px] from-slate-100  border border-transparent hover:border-white p-8 md:p-4 lg:p-8 xl:p-6 2xl:px-4 2xl:py-8`}
      initial="hidden"
      whileInView="visible"
      variants={cardVariants}
    >
      <img
        src={imgSrc}
        className={`${type === "client" ? 'h-[160px] w-[160px]': 'h-[150px] w-[150px]'} mb-2 object-none ${objectPosition} group-hover:drop-shadow-2xl`}
        alt="icon"
        loading="lazy"
      />
      <span className="text-[32px] font-semibold text-white mb-4 group-hover:text-blue">
        {title}
      </span>
      <span
        // className="text-[22px] font-medium w-3/4 text-slate-300"
        className={`text-[22px] font-medium text-slate-300 group-hover:text-orange
        `}
        // ${type === "user" ? (index === 0 || index === 3 ? "w-3/4" : "w-5/6") : ""}
      >
        {content}
      </span>
    </motion.div>
  );
};

export default AnimatedCard;
