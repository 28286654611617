import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { motion } from "motion/react";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function Header() {
  const { t } = useTranslation();

  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [navCTAChanger, setnavCTAChanger] = useState(location.pathname);
  const navigation = [
    { name: "header_home", href: "/", current: true },
    { name: "header_about_us", href: "/about", current: false },
    { name: "header_contact_us", href: "/contact", current: false },
    {
      name: `${
        navCTAChanger === "/client"
          ? "header_need_job"
          : "header_need_employees"
      }`,
      href: `${
        navCTAChanger === "/client" ? "/" : "/client"
      }`,
      current: false,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Disclosure
      as="nav"
      className="bg-blue fixed top-0 right-0 left-0 w-full z-50 sm:p-2"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-2 sm:px-6 xl:px-12">
            <div className="relative flex h-16 items-center justify-between">
              {/* Mobile menu button */}
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              {/* Logo */}
              <div className="flex flex-1 items-center justify-center sm:items-center lg:justify-between">
                <a href="/" className="flex-shrink-0">
                  <img
                    src={Logo}
                    className="h-10 sm:h-16 w-auto"
                    alt="Kaigal"
                  />
                </a>
                {/* Desktop Menu */}
                <div className="hidden sm:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.name === "header_need_employees" ||
                            item.name === "header_need_job"
                            ? " bg-orange text-white px-4 py-2 rounded-lg"
                            : item.current
                            ? "bg-blue-700 text-white"
                            : "text-white hover:bg-blue-500",
                          `rounded-md px-3 py-2 text-lg font-bold ${
                            item.name === "header_need_employees" ||
                            item.name === "header_need_job"
                              ? "hover:text-white hover:bg-green"
                              : "hover:text-orange"
                          }`
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {t(item.name)}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              {/* Right Section */}
              <div className="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Language Switcher */}
                <LanguageSwitcher />
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          <DisclosurePanel
            as={motion.div}
            className="lg:hidden"
            initial={{ opacity: 0, height: 0 }}
            // animate={{ opacity: open ? 1 : 0, height: open ? "auto" : 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
              duration: 0.3,
              ease: "easeInOut",
              opacity: { duration: 1 }, // Slow fade-out
              height: { duration: 1 }, // Slow height collapse
            }}
          >
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <motion.div
                  key={item.name}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 * navigation.indexOf(item) }}
                >
                  <DisclosureButton
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.name === "header_need_employees" ||
                        item.name === "header_need_job"
                        ? "bg-orange text-white px-4 py-2 rounded-lg"
                        : item.current
                        ? "bg-blue-700 text-white"
                        : "text-white hover:bg-blue-500",
                      "block rounded-md px-3 py-2 text-base font-medium text-left"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {t(item.name)}
                  </DisclosureButton>
                </motion.div>
              ))}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}
