import LeftBg from "../../assets/way-to-register/bg-pattern-Left-Svg.svg";
import RightBg from "../../assets/way-to-register/bg-pattern-Right-svg.svg";
// import MissedImage from "../../assets/Missed call skeu.svg";
import MissedImage from "../../assets/way-to-register/missed.svg";
import WhatsappImage from "../../assets/way-to-register/whatsapp.svg";
import AppImage from "../../assets/way-to-register/download.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function WayToRegister(props) {
  const { t } = useTranslation();
  return (
    <section
      style={{ backgroundImage: `url(${LeftBg})` }}
      className={`bg-contain bg-no-repeat bg-left ${
        props.type === "client" ? "bg-[#F2F2F2] py-16" : ""
      }`}
    >
      <div
        style={{ backgroundImage: `url(${RightBg})` }}
        className={` bg-contain bg-no-repeat bg-right`}
      >
        <div className="m-8 sm:m-16">
          <h4 className="text-5xl font-semibold mb-12">
            {t("home_ways_to_register_way_to")}{" "}
            <span className="text-orange">
              {t("home_ways_to_register_register")}
            </span>
          </h4>
          <div
            className={`grid gap-5 ${
              props.type === "client"
                ? "grid-cols-1 lg:grid-cols-2 justify-items-center"
                : "grid-cols-1 lg:flex lg:justify-center justify-items-stretch lg:gap-12 xl:grid-cols-3"
            }`}
          >
            <div
              className={`flex justify-between xl:justify-evenly flex-col p-8 md:12 bg-[#D5D4E4] rounded-[30px] group ${
                props.type === "client"
                  ? "w-full md:w-2/3 lg:w-4/5 xl:w-2/6 2xl:w-[30rem] xl:aspect-square 2xl:aspect-[5/4.5]"
                  : "w-full mx-auto lg:mx-0 md:w-2/3 lg:w-4/5 xl:w-2/6 2xl:w-[27rem] xl:aspect-square 2xl:aspect-[5/4.5]"
              }`}
            >
              <div className="bg-gradient-to-t from-[#FFFFFF] to-[#DFDFDF] flex h-[150px] mx-auto w-[150px] rounded-[20px] shadow-inner-combo drop-shadow-2xl items-center justify-center mb-6">
                <img
                  src={MissedImage}
                  loading='lazy'
                  className="h-[100px] group-hover:-translate-y-2"
                  alt="Missed Call"
                  srcSet=""
                />
              </div>
              <a href="tel:+918980912345" className="block">
                <span className="text-2xl font-semibold bg-green text-white p-5 rounded-[12px] md:rounded-[16px] lg:rounded-[30px] block group-hover:bg-orange">
                  {props.type === "client" ? t("home_ways_to_register_call_client_service") : t("home_ways_to_register_call_candidate_service")}
                </span>
              </a>
            </div>
            {props.type === "client" ? (
              ""
            ) : (
              <div
                className={`flex justify-between xl:justify-evenly flex-col p-8 md:12 bg-[#D5D4E4] rounded-[30px] group ${
                  props.type === "client"
                    ? "w-full md:w-2/3 lg:w-4/5 xl:w-2/6 2xl:w-[30rem] xl:aspect-square 2xl:aspect-[5/4.5]"
                    : "w-full mx-auto lg:mx-0 md:w-2/3 lg:w-4/5 xl:w-2/6 2xl:w-[27rem] xl:aspect-square 2xl:aspect-[5/4.5]"
                }`}
              >
                <div className="bg-gradient-to-t from-[#FFFFFF] to-[#DFDFDF] flex h-[150px] mx-auto w-[150px] rounded-[20px] shadow-inner-combo drop-shadow-2xl items-center justify-center mb-6">
                  <img
                    src={WhatsappImage}
                    loading='lazy'
                    className="h-[100px] group-hover:-translate-y-2"
                    alt="Missed Call"
                    srcSet=""
                  />
                </div>
                <a
                  href={`https://api.whatsapp.com/send/?phone=${
                    props.type === "client" ? 918122734380 : 918122734380 // Changed from 918925942739 this to this 918122734380
                  }&text=hi`}
                  className="block"
                >
                  <span className="text-2xl font-semibold bg-green text-white p-5 rounded-[12px] md:rounded-[16px] lg:rounded-[30px] block group-hover:bg-orange">
                    {t("home_ways_to_register_register_via_whatsapp")}
                  </span>
                </a>
              </div>
            )}
            <div
              className={`flex justify-between xl:justify-evenly flex-col p-8 md:12 bg-[#D5D4E4] rounded-[30px] group ${
                props.type === "client"
                  ? "w-full md:w-2/3 lg:w-4/5 xl:w-2/6 2xl:w-[30rem] xl:aspect-square 2xl:aspect-[5/4.5]"
                  : "w-full mx-auto lg:mx-0 md:w-2/3 lg:w-4/5 xl:w-2/6 2xl:w-[27rem] xl:aspect-square 2xl:aspect-[5/4.5]"
              }`}
            >
              <div className="bg-gradient-to-t from-[#FFFFFF] to-[#DFDFDF] flex h-[150px] mx-auto w-[150px] rounded-[20px] shadow-inner-combo drop-shadow-2xl items-center justify-center mb-6">
                <img
                  src={AppImage}
                  loading='lazy'
                  className="h-[100px] group-hover:-translate-y-2"
                  alt="Missed Call"
                  srcSet=""
                />
              </div>
              <a
                href={
                  props.type === "client"
                    ? `https://play.google.com/store/apps/details?id=com.kaigal.clapp&hl=${i18n.language}_IN&gl=US`
                    : `https://play.google.com/store/apps/details?id=com.kaigal.jobsapp&hl=${i18n.language}_IN&gl=US`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <span className="text-2xl font-semibold bg-green text-white p-5 rounded-[12px] md:rounded-[16px] lg:rounded-[30px] block group-hover:bg-orange">
                  {props.type === "client" ? t("home_ways_to_register_download_kaigal_recruit_app") : t("home_ways_to_register_download_kaigal_jobs_app")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
