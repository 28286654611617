import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import LeftVector from "../../assets/faq/left-Patterns.svg";
import RightVector from "../../assets/faq/right-element.svg";
import { useLocation } from "react-router-dom";

const FAQItem = ({ question, answer, isOpen, onToggle }) => {
  return (
    <div className="border-b-2 border-[#EAECF0] mt-6 pb-6" onClick={onToggle}>
      <div className="flex cursor-pointer items-center justify-between gap-1.5">
        <h3 className="font-medium text-lg text-start">{question}</h3>
        <span className="relative size-5 shrink-0">
          {/* Plus Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`absolute inset-0 size-5 ${
              isOpen ? "opacity-0" : "opacity-100"
            } transition-opacity`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          {/* Minus Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`absolute inset-0 size-5 ${
              isOpen ? "opacity-100" : "opacity-0"
            } transition-opacity`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden mt-2 text-[#667085]"
          >
            <p className="mb-4 text-start">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default function FrequentlyAskedQuestions() {
  const { t } = useTranslation();
  const faqList = [
    { question: t("home_faq_section_what_is_kaigal"), answer: t("home_faq_section_what_is_kaigal_answer") },
    { question: t("home_faq_section_how_post_job"), answer: t("home_faq_section_how_post_job_answer") },
    { question: t("home_faq_section_how_identify_candidates"), answer: t("home_faq_section_how_identify_candidates_answer") },
    { question: t("home_faq_section_improve_chances_employees"), answer: t("home_faq_section_improve_chances_employees_answer") },
    { question: t("home_faq_section_check_job_status"), answer: t("home_faq_section_check_job_status_answer") },
    { question: t("home_faq_section_troubleshoot_app"), answer: t("home_faq_section_troubleshoot_app_answer") },
  ];

  const [activeIndex, setActiveIndex] = useState(0); // First item is open by default

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle or switch to another question
  };
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#faq") {
      setTimeout(() => {
        const faqSection = document.getElementById("faq");
        if (faqSection) {
          faqSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);  // Delay to allow component rendering
    }
  }, [location]);
  

  return (
    <section id="faq">
      <div
        style={{ '--home--faq-md-lt-bg': `url(${LeftVector})` }}
        className={`sm:min-h-dvh bg-no-repeat lg:bg-left-top bg-[image:var(--home--faq-md-lt-bg)] bg-[-8rem_top] md:bg-[-10rem_top] sm:bg-left`}
      >
        <div
          style={{ '--home--faq-md-rt-bg': `url(${RightVector})` }}
          className={`bg-none sm:min-h-dvh bg-no-repeat bg-right md:bg-[image:var(--home--faq-md-rt-bg)]`}
        >
          <h1 className="text-5xl font-semibold pt-24">{t("home_faq_section")}</h1>
          <p className="text-lg font-semibold mt-5 text-[#5C5B5B]/60">
            {t("home_faq_section_product_billing_info")}
          </p>
          <div className="flex flex-col justify-center items-center mt-12">
            <div className="w-3/4 sm:w-1/2">
              {faqList.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={activeIndex === index}
                  onToggle={() => handleToggle(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
