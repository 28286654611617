import HeroImage from "../../assets/kaigal-hero.webp";
import HeroBg from "../../assets/bgVector.svg";
import GooglePlay from "../../assets/Google_Play_Store_badge_EN.svg.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import i18n from "../../i18n";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ "--home--hero-sm-bg": `url(${HeroBg})` }}
      className={`lg:flex items-center justify-between lg:bg-[image:var(--home--hero-sm-bg)] bg-contain bg-no-repeat bg-right-bottom sm:min-h-[89dvh] lg:min-h-[100dvh]`}
    >
      <section className="lg:basis-12/12 xl:basis-auto flex flex-col content-around p-8 mt-24 sm:ps-10 lg:ps-6 xl:ps-16">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="text-5xl font-bold text-left mb-6"
        >
          <span className="text-blue">{t("home_hero_unlock_your")}</span>{" "}
          <span className="bg-gradient-to-r from-[#2B2A7B] to-[#008894] text-transparent bg-clip-text">
            {t("home_hero_dream_career")}
          </span>
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
          className={`text-lg text-left mb-6 ${i18n.language==='tn'? 'xl:w-4/5':"xl:w-4/5" }`}
        >
          {t("home_hero_empower_statement")}
        </motion.p>

        <motion.a
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, ease: "easeOut", delay: 0.4 }}
          className="bg-orange sm:w-1/3 lg:w-1/2 xl:w-1/3 py-4 text-white font-medium rounded-[10px] text-base mb-6 hover:bg-green hover:shadow-lg hover:shadow-green/[0.5]"
          href={`https://jobs.kaigal.com/?lang=${i18n.language}&utm_source=website&utm_medium=find_jobs_${i18n.language}&utm_campaign=candidate_${i18n.language}`}
          // href={}
          target="_blank"
        >
          {t("home_hero_find_jobs")}
        </motion.a>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
          className={`text-blue font-bold text-lg text-left mb-6 ${i18n.language==='tn'? 'xl:w-4/5':"xl:w-4/5" }`}
        >
          {t("home_hero_download_app")}
        </motion.p>

        <div className="sm:flex justify-start items-center gap-x-4 grid grid-cols-2 gap-4">
          <motion.a
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.9, ease: "easeOut", delay: 0.8 }}
            // className="bg-black flex-basis px-4 py-2 text-white font-medium rounded-[10px] text-lg flex justify-start items-center text-left"
            className="flex-basis"
            href={`https://play.google.com/store/apps/details?id=com.kaigal.jobsapp&hl=${i18n.language}_IN&gl=US`}
          >
            <img src={GooglePlay} loading='lazy' alt="google play link" className="hover:shadow-lg hover:shadow-black/[0.25] rounded" style={{maxHeight:"52px"}} />
          </motion.a>

          <motion.a
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.9, ease: "easeOut", delay: 1 }}
            className="bg-black flex-basis px-4 py-2 text-white font-medium rounded-[10px] text-lg flex justify-start items-center text-left hover:shadow-lg hover:shadow-black/[0.5]"
            href="tel:+918980912345"
          >
            <span className="material-symbols-outlined text-3xl">
              phone_in_talk
            </span>
            <span className="flex-col ps-3">
              <p className="w-full text-xs">Give missed call to</p>
              <p className="text-xs">89809-12345</p>
            </span>
          </motion.a>

          <motion.a
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9, ease: "easeOut", delay: 1.2 }}
            className="bg-green flex-basis text-white font-medium rounded-[10px] text-sm py-4 px-5 col-span-2 hover:bg-orange hover:shadow-lg hover:shadow-orange/[0.5]"
            href="/client"
          >
            {t("home_need_employee")}
          </motion.a>
        </div>
      </section>

      <section
        // style={{ backgroundImage: `url(${HeroBg})` }}
        className={`lg:basis-10/12 xl:basis-auto self-end bg-no-repeat bg-[image:var(--home--hero-sm-bg)] lg:bg-none bg-right-bottom`}
      >
        <motion.img
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.8 }}
          src={HeroImage}
          fetchpriority="high"
          loading="eager"
          className="sm:w-3/4  lg:w-11/12 justify-self-end"
          alt="a man"
          height="436px"
          width="430px"
        />
      </section>
    </motion.main>
  );
}
