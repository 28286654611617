import { useTranslation } from "react-i18next";
import contactUsImage from "../assets/contact/contact-us.svg";
import LeftVector from "../assets/contact/left.svg";
import RightVector from "../assets/contact/right.svg";
export default function Contact() {
  const { t } = useTranslation();
  return (
    <section
      style={{
        "--contact-md-lt-bg": `url(${LeftVector})`
      }}
      className={`sm:min-h-[89dvh] lg:min-h-[85dvh] mt-18 sm:mt-20 bg-no-repeat bg-left-bottom bg-[image:var(--contact-md-lt-bg)] `}
    >
      <div style={{
        "--contact-md-rt-bg": `url(${RightVector})`,
      }} className={`sm:min-h-[89dvh] lg:min-h-[80dvh] py-28 bg-no-repeat bg-[top_right_-1rem] md:bg-right-top bg-[image:var(--contact-md-rt-bg)]`}>
        <h1 className="text-5xl text-orange font-semibold block">
          {/* Contact Us */}
          {t("header_contact_us")}
        </h1>
        <span className="block p-1 text-lg font-semibold text-[#5C5B5B]/70 md:w-2/3 lg:w-1/2 text-center mx-auto">
          {t("contact_us_at_kaigal")}
          {/* At Kaigal, we're here to assist both job seekers and employers. Whether
        you're looking for job opportunities or hiring talent, feel free to
        reach out. */}
        </span>
        <div className="flex flex-col lg:flex-row justify-around items-center my-10 sm:my-20 sm:w-10/12 sm:mx-auto">
          <div className="flex flex-col justify-start items-start gap-4">
            <span className="text-3xl text-orange font-semibold block">
              {t("contact_us_communication")}
            </span>
            <div>
              <span>Email: </span>
              <a href="mailto:connect@kaigal.com">connect@kaigal.com</a>
            </div>
            {/*<div>*/}
            {/*  <span>For Business Enquiries: </span>*/}
            {/*  <a href="tel:+919150502642">+91 91-5050-2642</a>*/}
            {/*</div>*/}
            <div>
              <span>For Business Enquiries and candidate registration: </span>
              <a href="tel:+918980912345">89809-12345</a>
            </div>
            <div>
              <address className="not-italic">
                CIN - U93030TN2019PTC128641
              </address>
            </div>
            <div>
              <address className="not-italic font-semibold">
                Paramathi Velur, Namakkal & Taramani, Chennai, Tamil Nadu, India
              </address>
            </div>
            <div className="flex flex-col justify-stretch items-start gap-4 w-full">
              <a
                  className="bg-green text-white font-medium rounded p-3 block w-3/4"
                  href="/client"
              >
                {t("header_need_employees")}?{/* Need Employees? */}
              </a>
              <a
                  className="bg-green text-white font-medium rounded p-3 block w-3/4"
                  href="/"
              >
                {t("header_need_job")}?{/* Need Jobs? */}
              </a>
            </div>
          </div>
          <div className="mt-5">
            <img src={contactUsImage} alt="contact" loading='lazy'/>
          </div>
        </div>
      </div>
    </section>
  );
}
