import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
// import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export default function LanguageSelector() {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const languages = [
    { code: "en", label: "header_lang_english" },
    { code: "hi", label: "header_lang_hindi" },
    { code: "ta", label: "header_lang_tamil" },
    // { code: "ml", label: "header_lang_malayalam" },
    // { code: "kn", label: "header_lang_kannada" },
    { code: "te", label: "header_lang_telugu" },
    // { code: "or", label: "header_lang_odia" },
    // { code: "bn", label: "header_lang_bengali" },
    // { code: "mr", label: "header_lang_marathi" },
    // { code: "gu", label: "header_lang_gujarati" },
    // { code: "as", label: "header_lang_assamese" },
  ];

  useEffect(() => {
    // Check if a language is already saved in localStorage
    const savedLanguage = localStorage.getItem("preferredLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Set the saved language
    } else {
      setOpen(true); // Show modal if no preferred language is found
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLanguageSelection = (lang) => {
    console.log(lang);
    setSelectedLanguage(lang);
    localStorage.setItem("preferredLanguage", lang); // Save the selected language
    i18n.changeLanguage(lang); // Set language dynamically without reload
    setOpen(false);
  };

  // The two buttons are removed from the screen, so I'm commenting out the lines
  // const handleConfirm = () => {
  //   if (selectedLanguage) {
  //     handleLanguageSelection(selectedLanguage);
  //   }
  // };
  //
  // const handleCancel = () => {
  //   setOpen(false); // Close the modal
  // };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center sm:items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-blue px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-b-lg drop-shadow-[0_4px_2px_rgba(0,136,148,1)]">
              <div className="sm:flex sm:items-center overflow-y-hidden">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:size-14 scale-[3] sm:scale-[3] -rotate-12">
                  {/* <div className="mx-auto flex size-12 sm:size-14 shrink-0 items-center justify-center scale-[3] -rotate-12"> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-globe text-green"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-slate-50"
                  >
                    Select Your Preferred Language
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-xs text-slate-300">
                      Choose the language you are most comfortable with to
                      explore Kaigal effortlessly. You can always update this in
                      your account settings
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<button*/}
            {/*    key={lang.code}*/}
            {/*    onClick={() => setSelectedLanguage(lang.code)}*/}
            {/*    // onClick={() => console.log(lang.code)}*/}
            {/*    className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange bg-blue*/}
            {/*        ${*/}
            {/*        lang.code === selectedLanguage*/}
            {/*            ? "bg-orange text-white"*/}
            {/*            : "text-gray-700"*/}
            {/*    }*/}
            {/*          hover:text-blue`}*/}
            {/*>*/}
            {/*  {t(lang.label)}*/}
            {/*</button>*/}
            <div className="px-4 py-3 grid grid-cols-2 gap-2 sm:gap-4 sm:grid-cols-4 sm:p-6 sm:py-8">
              {languages.map((lang) => (
                  <button
                      key={lang.code}
                      onClick={() => handleLanguageSelection(lang.code)}
                      className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange bg-blue ${
                                lang.code === selectedLanguage
                                    ? "bg-orange text-white"
                                    : "text-gray-700"
                                } hover:text-blue`} > {t(lang.label)}
                  </button>
              ))}
            </div>
            {/*<div className="flex justify-around items-center mt-2 mb-6 w-10/12 mx-auto text-white">*/}
            {/*  <button*/}
            {/*    className="bg-green w-2/5 py-2 border rounded-lg hover:bg-green/[0.8] hover:text-blue hover:border-blue hover:font-semibold"*/}
            {/*    onClick={() => handleCancel()}*/}
            {/*  >*/}
            {/*    Cancel*/}
            {/*  </button>*/}
            {/*  <button*/}
            {/*    className="bg-orange w-2/5 py-2 border rounded-lg hover:bg-orange/[0.8] hover:text-blue hover:border-blue hover:font-semibold"*/}
            {/*    onClick={() => handleConfirm()}*/}
            {/*  >*/}
            {/*    Confirm*/}
            {/*  </button>*/}
            {/*</div>*/}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
