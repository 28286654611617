import StepOneImage from "../../assets/client/howitworks/step-1.svg";
import StepTwoImage from "../../assets/client/howitworks/step-2.svg";
import StepThreeImage from "../../assets/client/howitworks/step-3.svg";
import StepFourImage from "../../assets/client/howitworks/step-4.svg";
import StepFiveImage from "../../assets/client/howitworks/step-5.svg";
import StepSixImage from "../../assets/client/howitworks/step-6.svg";
import PatternLeft from "../../assets/how-it-works/Pattern-left.svg";
import BgVector from "../../assets/how-it-works/bg-vector-wave.svg";
import PatternRight from "../../assets/how-it-works/pattern-right.svg";
import ArrowOne from "../../assets/client/howitworks/arrow-one.svg";
import ArrowTwo from "../../assets/client/howitworks/arrow-two.svg";
// import ThreeFourArrow from "../../assets/how-it-works/3-4-arrow.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ClientHowItWorks() {
  const { t } = useTranslation();
  // const responsiveSettings = [
  // {
  //     breakpoint: 800,
  //     settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3
  //     }
  // },
  //   {
  //     breakpoint: 500,
  //     settings: {
  //       slidesToShow: 4,
  //       slidesToScroll: 2,
  //     },
  //   },
  // ];
  return (
    <section className="my-16">
      <h6 className="text-5xl text-orange text-center font-semibold mb-4 me-10">
        {t("home_how_it_works")}
      </h6>
      <span className="text-lg text-center block font-semibold me-10 text-[#5C5B5B]/70">
        {t("client_how_it_works_6_simple_steps")}
        {t("client_how_it_works_hr_team")}
      </span>
      <div
        className={`bg-no-repeat bg-[top_left_-2rem] sm:bg-left-top`}
        style={{ backgroundImage: `url(${PatternLeft})` }}
      >
        <div
          className={`bg-no-repeat bg-[bottom_right_-1rem] sm:bg-right-bottom`}
          style={{ backgroundImage: `url(${PatternRight})` }}
        >
          <div
            className={`bg-no-repeat bg-center bg-contain flex flex-col gap-10`}
            style={{ backgroundImage: `url(${BgVector})` }}
          >
            {" "}
            <div className="flex items-center justify-evenly m-8">
                  <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                    {t("client_how_it_works_step_1")}
                  </span>
                  <span className="text-3xl font-semibold">
                    {t("client_how_it_works_register")}
                  </span>
                </div>
                <span className="block text-2xl text-justify">
                  {t("client_how_it_works_create_one")}
                    {" "}
                  {t("client_how_it_works_create_two")}
                </span>
              </div>
              <div className="drop-shadow-2xl hidden lg:block">
                <img src={StepOneImage} alt="" loading='lazy' srcSet="" />
              </div>
            </div>
            <div className="relative flex items-center justify-evenly m-8">
              <img
                className="absolute right-20 -top-3/4 hidden xl:block"
                src={ArrowOne}
                alt=""
                loading='lazy'
                srcSet=""
              />
              <div className="drop-shadow-2xl hidden lg:block">
                <img src={StepTwoImage} loading='lazy' alt="" srcSet="" />
              </div>
              <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                    {t("client_how_it_works_step_2")}
                  </span>
                  <span className="text-3xl font-semibold">
                    {t("client_how_it_works_post_job")}
                  </span>
                </div>
                <span className="block text-2xl text-justify">
                  {t("client_how_it_works_add_essential_one")}
                  {t("client_how_it_works_reach_wide_two")}
                </span>
              </div>
            </div>
            <div className="relative flex items-center justify-evenly m-8">
              <img
                className="absolute left-20 -top-3/4 hidden xl:block"
                src={ArrowTwo}
                loading='lazy'
                alt=""
                srcSet=""
              />
              <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                    {t("client_how_it_works_step_3")}
                  </span>
                  <span className="text-3xl font-semibold">
                    {t("client_how_it_works_choose_plan")}
                  </span>
                </div>
                <span className="block text-2xl text-justify">
                  {t("client_how_it_works_select_package_one")}
                  {t("client_how_it_works_transparent_pricing_two")}
                </span>
              </div>
              <div className="drop-shadow-2xl hidden lg:block">
                <img src={StepThreeImage} loading='lazy' alt="" srcSet="" />
              </div>
            </div>
            <div className="relative flex items-center justify-evenly m-8">
              <img
                className="absolute right-20 -top-3/4 hidden xl:block"
                src={ArrowOne}
                loading='lazy'
                alt=""
                srcSet=""
              />
              <div className="drop-shadow-2xl hidden lg:block">
                <img src={StepFourImage} loading='lazy' alt="" srcSet="" />
              </div>
              <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                    {t("client_how_it_works_step_4")}
                  </span>
                  <span className="text-3xl font-semibold">
                    {t("client_how_it_works_get_method")}
                  </span>
                </div>
                <span className="block text-2xl text-justify">
                  {t("client_how_it_works_our_advanced_one")}
                  {t("client_how_it_works_fast_efficient_two")}
                </span>
              </div>
            </div>
            <div className="relative flex items-center justify-evenly m-8">
              <img
                className="absolute left-20 -top-3/4 hidden xl:block"
                src={ArrowTwo}
                loading='lazy'
                alt=""
                srcSet=""
              />
              <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                    {t("client_how_it_works_step_5")}
                  </span>
                  <span className="text-3xl font-semibold">
                    {t("client_how_it_works_review_candidates")}
                  </span>
                </div>
                <span className="block text-2xl text-justify">
                  {t("client_how_it_works_see_our_one")}
                  {t("client_how_it_works_see_our_two")}
                </span>
              </div>
              <div className="drop-shadow-2xl hidden lg:block">
                <img src={StepFiveImage} loading='lazy' alt="" srcSet="" />
              </div>
            </div>
            <div className="relative flex items-center justify-evenly m-8">
              <img
                className="absolute right-20 -top-3/4 hidden xl:block"
                src={ArrowOne}
                alt=""
                loading='lazy'
                srcSet=""
              />
              <div className="drop-shadow-2xl hidden lg:block">
                <img src={StepSixImage} loading='lazy' alt="" srcSet="" />
              </div>
              <div className="flex flex-col justify-start sm:items-start items-center gap-5 md:w-4/5 lg:w-2/5">
                    <div className="flex gap-3.5 flex-col sm:flex-row sm:justify-start items-center">
                      <span className="text-blue font-black text-5xl flex">
                    {t("client_how_it_works_step_6")}
                  </span>
                  <span className="text-3xl font-semibold">
                    {t("client_how_it_works_hire_team")}
                  </span>
                </div>
                <span className="block text-2xl text-justify">
                  {t("client_how_it_works_pay_final_one")}
                  {t("client_how_it_works_pay_final_two")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        href={`https://recruit.kaigal.com/?lang=${i18n.language}&utm_source=website&utm_medium=get_started_${i18n.language}&utm_campaign=client_${i18n.language}`}
        target="_blank"
        rel="noreferrer"
        className="bg-orange py-4 rounded-lg text-white px-24 mt-16 inline-block hover:bg-green font-semibold"
      >
        {t("home_how_it_works_get_started")}
      </a>
    </section>
  );
}
