import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { useTranslation } from "react-i18next";
import ScrollIcon from "./ScrollDown";
import aboutData from "../aboutus/about.json";
import one from "../aboutus/images/one.svg";
import two from "../aboutus/images/two.svg";
import three from "../aboutus/images/three.svg";
import four from "../aboutus/images/four.svg";
import five from "../aboutus/images/five.svg";
import six from "../aboutus/images/six.svg";
import seven from "../aboutus/images/seven.svg";
import eight from "../aboutus/images/eight.svg";
import nine from "../aboutus/images/nine.svg";
import ten from "../aboutus/images/ten.svg";
import eleven from "../aboutus/images/eleven.svg";
import tweleve from "../aboutus/images/tweleve.svg";
import thirteen from "../aboutus/images/thirteen.svg";
import fourteen from "../aboutus/images/fourteen.svg";
import fifteen from "../aboutus/images/fifteen.svg";
import sixteen from "../aboutus/images/sixteen.svg";

const timeLineImages = {
  one: one,
  two: two,
  three: three,
  four: four,
  five: five,
  six: six,
  seven: seven,
  eight: eight,
  nine: nine,
  ten: ten,
  eleven: eleven,
  tweleve: tweleve,
  thirteen: thirteen,
  fourteen: fourteen,
  fifteen: fifteen,
  sixteen: sixteen
}

const Timeline = () => {
  const { i18n, t } = useTranslation();
  const [timelineItems, setTimelineItems] = useState([]);
  const [renderKey, setRenderKey] = useState(Date.now());

  useEffect(() => {
    // const fetchTimelineData = async () => {
    //   try {
    //     const response = await fetch(
    //       "https://raw.githubusercontent.com/prasathvjkmr/kaigal-translation/main/about/about.json"
    //     );
    //     const data = await response.json();
    //     setTimelineItems(data);
    //     setRenderKey(Date.now());
    //   } catch (error) {
    //     console.error("Error fetching timeline data: ", error);
    //   }
    // };
    // fetchTimelineData();
    setTimelineItems(aboutData);
    setRenderKey(Date.now());
  }, [i18n.language, t]);

  // useEffect(() => {
  // }, [i18n.language, t]);

  return (
    <div
      key={renderKey}
      className="flex justify-center items-center"
      id="timeline"
    >
      <div className="w-9/12 h-[65dvh] sm:w-8/12 sm:h-[80dvh] sm:overflow-hidden">
        <Chrono
          items={timelineItems.map((item) => ({
            title: t(item.title), // Month can be used as the title
            cardTitle: t(item.cardTitle), // Content of the event
          }))}
          mode="VERTICAL_ALTERNATING"
          showSingle
          slideItemDuration={2000}
          slideShow
          timelinePointDimension={50}
          fontSizes={{
            cardTitle: "1.25rem",
            title: "1.05rem",
          }}
          highlightCardsOnHover
          theme={{
            primary: "grey",
            secondary: "white",
            cardBgColor: "#EE6A29",
            cardForeColor: "violet",
            titleColor: "#2C2A7A",
            titleColorActive: "#EE6A29",
          }}
          cardHeight={85}
          hideControls={true}
        >
          <div className="chrono-icons">
            {timelineItems.map((img, index) => (
                <img src={timeLineImages[`${img.image}`]} key={index} alt="Icon" loading='lazy'/>
            ))}
          </div>
        </Chrono>
      </div>
      <ScrollIcon />
    </div>
  );
};

export default Timeline;
