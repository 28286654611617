import { useTranslation } from "react-i18next";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import StartUpTNImage from "../../assets/client/investors/tn.svg";
import TNIFMCImage from "../../assets/client/investors/tnifmc.svg";
import STPIImage from "../../assets/client/investors/stpi.svg";
import PontaqImage from "../../assets/client/investors/Pontaq.svg";

export default function Investors() {
    const { t } = useTranslation();
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  // Animation variants with responsive design
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.9, y: 30 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <section className="p-8 sm:p-16 block bg-[#F5F5F5]" ref={ref}>
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: -20 },
        }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="text-5xl font-semibold mb-8 text-center"
      >
        <span className="text-orange">{t("client_investor")}</span>
      </motion.h2>

        <motion.div
            className="flex flex-col sm:flex-row justify-around items-center font-semibold gap-8"
            initial="hidden"
            animate={controls}
            variants={{
                visible: {transition: {staggerChildren: 0.3}},
            }}
        >
            {/* Total Clients */}
            <motion.span
                variants={itemVariants}
                className="flex flex-col items-center"
            >
                <img
                    loading='lazy'
                    src={StartUpTNImage}
                    className="hover:scale-110 transition"
                    alt=""
                />
            </motion.span>

            {/* Candidates */}
            <motion.span
                variants={itemVariants}
                className="flex flex-col items-center"
            >
                <img
                    loading='lazy'
                    src={TNIFMCImage}
                    className="hover:scale-110 transition"
                    alt=""
                />
            </motion.span>

            {/* Interviews */}
            <motion.span
                variants={itemVariants}
                className="flex flex-col items-center"
            >
                <img src={STPIImage} className="hover:scale-110 transition" alt="" loading='lazy'/>
            </motion.span>

            <motion.span
                variants={itemVariants}
                className="flex flex-col items-center"
            >
                <img src={PontaqImage} className="hover:scale-110 transition" alt="" loading='lazy'/>
            </motion.span>
        </motion.div>
    </section>
  );
}
