import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import AnimatedCard from "../../components/AnimatedCard";
import getCardData from "../../components/UserCard";

export default function WhyChooseUs({ type }) {
  const { t } = useTranslation();
  const cards = getCardData(t, type);

  // Section animation
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <motion.section
      className="p-8 bg-green sm:p-16"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={sectionVariants}
    >
      <motion.h3
        className="text-5xl font-semibold mb-1 text-white"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        {t("home_why_choose_us")}
      </motion.h3>
      <span className="text-lg text-white font-semibold">
        {/* {t("home_why_choose_us_easiest_way_find_job")} */}
        {type === "client"
          ? t("client_why_choose_us_easiest_way")
          : t("home_why_choose_us_easiest_way_find_job")}
      </span>
      <div
        className={`grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 sm:gap-10 md:gap-x-4 lg:gap-x-12 md:gap-y-14 lg:gap-18 mt-10 ${
          type === "client" ? "xl:flex xl:justify-center flex-wrap" : ""
        }`}
      >
        {/* Render cards dynamically */}
        {cards.map((card, index) => (
          <AnimatedCard
            key={index}
            imgSrc={card.imgSrc}
            title={card.title}
            content={card.content}
            type={type}
            index={index}
            objectPosition={card.objectPosition}
          />
        ))}
      </div>
    </motion.section>
  );
}
