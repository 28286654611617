import CandidateWhyChooseUsSprites from "../assets/home/whyChooseUs/why-choose-us-sprite.png";
import ClientWhyChooseUsSprites from "../assets/client/whychooseus/why-choose-us-sprite.png";

// Helper function to generate card data
const getCardData = (t, type) => {
  if (type === "user") {
    return [
      {
        imgSrc: CandidateWhyChooseUsSprites,
        title: t("home_why_choose_us_absolutely_free"),
        content: t("home_why_choose_us_find_jobs_no_fee"),
        objectPosition: "object-[_-10rem_-8.75rem]",
      },
      {
        imgSrc: CandidateWhyChooseUsSprites,
        title: t("home_why_choose_us_jobs_come_to_you"),
        content: t("home_why_choose_us_job_opportunities_directly"),
        objectPosition: "object-[_-41.5rem_-40.5rem]",
      },
      {
        imgSrc: CandidateWhyChooseUsSprites,
        title: t("home_why_choose_us_easy_interaction"),
        content: t("home_why_choose_us_connect_offline"),
        objectPosition: "object-left-top",
      },
      {
        imgSrc: CandidateWhyChooseUsSprites,
        title: t("home_why_choose_us_multiple_choices"),
        content: t("home_why_choose_us_explore_variety_jobs"),
        objectPosition: "object-[_-20rem_-20rem]",
      },
      {
        imgSrc: CandidateWhyChooseUsSprites,
        title: t("home_why_choose_us_local_opportunities"),
        content: t("home_why_choose_us_convenient_jobs_near_home"),
        objectPosition: "object-[_-52rem_-51rem]",
      },
      {
        imgSrc: CandidateWhyChooseUsSprites,
        title: t("home_why_choose_us_open_to_all"),
        content: t("home_why_choose_us_anyone_find_job"),
        objectPosition: "object-[_-31rem_-30rem]",
      },
    ];
  } else if (type === "client") {
    return [
      {
        imgSrc: ClientWhyChooseUsSprites,
        title: t("client_why_choose_us_effortless_job"),
        content: t("client_why_choose_us_create_job"),
        objectPosition: "object-[-1rem]",
      },
      {
        imgSrc: ClientWhyChooseUsSprites,
        title: t("client_why_choose_us_qualified_candidates"),
        content: t("client_why_choose_us_attract_top"),
        objectPosition: "object-[_-18.75rem_-0.75rem]",
      },
      {
        imgSrc: ClientWhyChooseUsSprites,
        title: t("client_why_choose_us_transparent_pricing"),
        content: t("client_why_choose_us_enjoy_fixed"),
        objectPosition: "object-[_-36rem_-0.75rem]",
      },
      {
        imgSrc: ClientWhyChooseUsSprites,
        title: t("client_why_choose_us_smart_matching"),
        content: t("client_why_choose_us_our_technology"),
        objectPosition: "object-[_-54rem_-1rem]",
      },
      {
        imgSrc: ClientWhyChooseUsSprites,
        title: t("client_why_choose_us_trusted_by_thousand"),
        content: t("client_why_choose_us_join_thousands"),
        objectPosition: "object-[_-71rem_-0.75rem]",
      },
    ];
  }
};

export default getCardData;
