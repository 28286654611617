import RightVectorBg from "../assets/footer/right-bg.svg";
import LeftVectorBgImage from "../assets/footer/left-bg.svg";
import LeftCardVector from "../assets/layout/footer/left.webp";
import RightCardVector from "../assets/layout/footer/right.webp";
import CenterVector from "../assets/footer/center-logo.svg";
import PlayStoreLogo from "../assets/footer/play-store-logo.svg";
import RightUpLogo from "../assets/footer/up-logo.svg";
import KaigalLogo from "../assets/footer/logo-kaigal.svg";
import InstaLogo from "../assets/footer/insta-fill.svg";
import TwitterLogo from "../assets/footer/x-twitter-fill.svg";
import whatsappLogo from "../assets/footer/whatapp-logo.svg";
import FacebookLogo from "../assets/footer/facebook-fill.svg";
import LinkedInLogo from "../assets/footer/linkedin-fill.svg";
import GooglePlay from "../assets/footer/Google-play-button.svg";
import GiveUsCall from "../assets/footer/call-cta-button.svg";
import Telegram from "../assets/footer/Telegram-button.svg";
import WhatsApp from "../assets/footer/whatsapp-cta-button.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="bg-blue p-8 md:p-16">
      <div className="flex flex-col lg:flex-row gap-8 sm:gap-12 sm:justify-center items-center relative">
        <div
          className={`sm:basis-1/2 self-stretch rounded-[1.25rem] aspect-square sm:aspect-video self-start bg-cover bg-no-repeat bg-left-top p-8 sm:px-12 sm:py-5 flex flex-col justify-between sm:justify-around items-start`}
          style={{ backgroundImage: `url(${LeftVectorBgImage})` }}
        >
          <img
            className="h-[181px]"
            height="180px"
            width="180px"
            src={LeftCardVector}
            alt="job"
            loading='lazy'
          />
          <span className="text-white text-2xl font-bold">
            {t("footer_find_job_with_kaigal")}

            {/* Find a Job with */}
          </span>
          {/* <span className="text-white text-2xl font-bold">Kaigal!</span> */}
          <a
            className="text-white bg-orange p-4 rounded-lg font-bold text-sm flex gap-2 border border-transparent group hover:border-white hover:bg-white hover:text-black"
              href={`https://play.google.com/store/apps/details?id=com.kaigal.jobsapp&hl=${i18n.language}_IN&gl=US`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={PlayStoreLogo}
              alt=""
              className="group-hover:invert group-hover:scale-110 transition ease-in-out"
              srcSet=""
              loading='lazy'
            />
            {t("footer_download_app_text")}
            {/* Download the app */}
          </a>
        </div>
        <img
          className="w-[100px] sm:w-[190px] sm:absolute drop-shadow-2xl hover:drop-shadow-none hover:scale-125 transition ease-in-out"
          src={CenterVector}
          height="100px"
          width="100px"
          alt="kaigal"
          loading='lazy'
        />
        <div
          style={{ backgroundImage: `url(${RightVectorBg})` }}
          className={` sm:basis-1/2 self-stretch rounded-[1.25rem]  aspect-square sm:aspect-video self-end bg-cover bg-no-repeat bg-left-top p-8 sm:px-12 sm:py-5 flex flex-col justify-between sm:justify-around items-end`}
        >
          <img
            className="h-[180px]"
            height="180px"
            width="180px"
            src={RightCardVector}
            alt="employee"
            loading='lazy'
          />
          {/* <span className="text-white text-2xl font-bold">Hire the Right</span> */}
          <span className="text-white text-end text-2xl font-bold">
            {t("footer_hire_right_candidate")}
            {/* Candidate with Kaigal */}
          </span>
          <a
            className="text-white bg-green p-4 rounded-lg font-bold text-sm flex gap-2 group hover:border-white hover:bg-white hover:text-black"
            // href="http://recruit.kaigal.com/?lang=en&utm_source=website&utm_medium=get_start_en&utm_campaign=client_en"
            href={`https://recruit.kaigal.com/?lang=${i18n.language}&utm_source=website&utm_medium=post_jobs_${i18n.language}&utm_campaign=client_${i18n.language}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("footer_post_job_free")}
            {/* Post Job for Free */}
            <img
              src={RightUpLogo}
              alt=""
              className="group-hover:invert group-hover:-translate-y-2 group-hover:translate-x-2 transition ease-in-out"
              srcSet=""
              loading='lazy'
            />
          </a>
        </div>
      </div>
      <hr className="mt-16" />
      <div className="text-white grid grid-cols-2 gap-6 lg:gap-0 lg:grid-cols-4 items-start mt-6">
        <div className="justify-self-center sm:justify-self-start col-span-2 sm:col-auto">
          <a href="/">
            <img
              src={KaigalLogo}
              loading="lazy"
              height="97"
              width="209"
              alt="kaigal"
            />
          </a>
        </div>
        <div className="flex flex-col justify-start text-left">
          {/* <span className="underline underline-offset-4 mb-3">
            {t("footer_resources")}
          </span> */}
          <ol className="flex flex-col gap-3">
            <li>
              {" "}
              <a
                className="group hover:text-orange transition-all duration-500 ease-in-out inline-block hover:indent-1.5"
                href="/about"
              >
                {t("header_about_us")}
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-50"></span>
              </a>
            </li>
            <li>
              {" "}
              <a
                className="group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                href="/contact"
              >
                {t("header_contact_us")}
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-50"></span>
              </a>
            </li>
            <li>
              {" "}
              <a
                className="group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                href="/PrivacyPolicy"
              >
                {t("footer_terms_conditions")}{" "}
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-50"></span>
              </a>
            </li>
            <li>
              {" "}
              <Link
                className="group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                to="/#faq"
              >
                {t("footer_faq")}{" "}
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-50"></span>
              </Link>
            </li>
          </ol>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-1 gap-4 sm:gap-6 sm:col-auto">
          <div className="flex">
            <a
                href="https://www.instagram.com/kaigaljobs"
                target="_blank"
                className="flex gap-0.5 group hover:text-orange transition-all duration-500 ease-in-out inline-block hover:indent-1.5"
                rel="noreferrer"
            >
              <img
                  className="group-hover:rotate-[22.5deg] transition duration-300"
                  src={InstaLogo}
                  alt=""
                  srcSet=""
                  loading='lazy'
              />
              <span>Instagram</span>
            </a>
          </div>
          <div className="flex">
            <a
                href="https://twitter.com/kaigalindia"
                target="_blank"
                className="flex gap-0.5 group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                rel="noreferrer"
            >
              <img
                  className="group-hover:rotate-[22.5deg] transition duration-300"
                  src={TwitterLogo}
                  alt=""
                  srcSet=""
                  loading='lazy'
              />
              <span>Twitter X</span>
            </a>
          </div>
          <div className="flex">
            <a
                href="https://whatsapp.com/channel/0029Va9qvHIEQIapJFVRVh0H"
                target="_blank"
                className="flex gap-0.5 group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                rel="noreferrer"
            >
              <img
                  className="group-hover:rotate-[22.5deg] transition duration-300"
                  src={whatsappLogo}
                  alt=""
                  srcSet=""
                  loading='lazy'
              />
              <span>Whatsapp</span>
            </a>
          </div>
          <div className="flex">
            <a
                href="https://www.facebook.com/profile.php?id=61563513844257"
                target="_blank"
                className="flex gap-0.5 group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                rel="noreferrer"
            >
              <img
                  className="group-hover:rotate-[22.5deg] transition duration-300"
                  src={FacebookLogo}
                  alt=""
                  srcSet=""
                  loading='lazy'
              />
              <span>Facebook</span>
            </a>
          </div>
          <div className="flex">
            <a
                href="https://www.linkedin.com/company/kaigalindia"
                target="_blank"
                className="flex gap-0.5 group hover:text-orange transition-all duration-300 ease-in-out inline-block hover:indent-1.5"
                rel="noreferrer"
            >
              <img
                  className="group-hover:rotate-[22.5deg] transition duration-300"
                  src={LinkedInLogo}
                  alt=""
                  srcSet=""
                  loading='lazy'
              />
              <span>Linked In</span>
            </a>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 col-span-2 sm:col-auto gap-4 ms-auto lg:me-12 w-full lg:w-3/4">
          <a
              href={`https://play.google.com/store/apps/details?id=com.kaigal.jobsapp&hl=${i18n.language}_IN&gl=US`}
              target="_blank"
              rel="noreferrer"
              className="group"
          >
            <img
                className="w-full group-hover:-translate-y-1 border border-transparent group-hover:border-gray-50 rounded-lg transition-all duration-200 ease-in-out"
                src={GooglePlay}
                alt=""
                srcSet=""
                loading='lazy'
            />
          </a>
          <a href="tel:+918980912345" className="group">
            <img
              className="w-full group-hover:-translate-y-1 border border-transparent group-hover:border-gray-50 rounded-lg transition-all duration-200 ease-in-out"
              src={GiveUsCall}
              alt=""
              srcSet=""
              loading='lazy'
            />
          </a>
          <a
            href="https://t.me/KaigalBot"
            target="_blank"
            className="group"
            rel="noreferrer"
          >
            <img
              className="w-full group-hover:-translate-y-1 border border-transparent group-hover:border-gray-50 rounded-lg transition-all duration-200 ease-in-out"
              src={Telegram}
              alt=""
              srcSet=""
              loading='lazy'
            />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=918122734380&text=Hi"
            target="_blank"
            rel="noreferrer"
            className="group"
          >
            <img
              className="w-full group-hover:-translate-y-1 border border-transparent group-hover:border-gray-50 rounded-lg transition-all duration-200 ease-in-out"
              src={WhatsApp}
              alt=""
              srcSet=""
              loading='lazy'
            />
          </a>
          <a
            href="/client"
            className="col-span-2 bg-orange p-2 rounded-lg hover:bg-green transition-all duration-100 ease-in-out"
          >
            {t("footer_need_employee")}
          </a>
          <a
            href="/"
            className="col-span-2 bg-orange p-2 rounded-lg hover:bg-green transition-all duration-100 ease-in-out"
          >
            {t("footer_need_job")}
          </a>
        </div>
      </div>
    </footer>
  );
}
