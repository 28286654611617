import React from 'react';
import scrollDown from '../assets/about/timeline/scroll-down.gif'

// Component for the animated scroll hand icon
const ScrollIcon = () => {
  return (
    <div className="flex justify-center items-center relative">
      <div className="text-4xl text-gray-700 relative w-[48px] h-[100px] overflow-hidden">
        <img
          src={scrollDown} // Replace with your actual GIF URL
          alt="Scroll Down"
          className="z-[10] absolute scale-[3] top-[2rem] right-0 w-[48px] object-contain"
          loading='lazy'
        />
      </div>
    </div>
  );
};

export default ScrollIcon;
